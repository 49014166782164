import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CustomerDetails from '../order-details-view/CustomerDetails';
import { useSectionContext } from '../../contexts/SectionContext';
import { useCustomerContext } from '../../contexts/CustomerContext';
import CustomTabPanel from '../mui-helpers/CustomTabPanel';
import Loader from '../mui-helpers/Loader';
import Receipt from './receipt/Receipt';
import WalletDetailsHeader from './wallet/WalletDetailsHeader';
import WalletTransaction from './wallet/WalletTransactions';
import PunchCardList from './trade-drivers/PunchCardList';
import Offers from './offer/Offers';
import { useTranslation } from 'react-i18next';
import { RetryRequestNames } from '../../types/RetryRequestNames';
import { ICustomTab } from '../mui-helpers/ICustomTab';
import useFetchCustomerData from '../../hooks/useFetchCustomerData';
import createTab from '../mui-helpers/createTab';
import { TabIds, TabNames } from '../../types/LoyaltyTabsConfig';

const Loyalty = () => {
  const {
    customerBrand,
    customerDetails,
    profileDetails,
    setCustomerDetails,
    setProfileDetails,
  } = useCustomerContext();
  const { section, setSection } = useSectionContext();
  const { t } = useTranslation();

  const { state, dispatch } = useFetchCustomerData(
    customerDetails?.email ?? '',
    customerDetails?.uid ?? '',
    profileDetails?.trackingId ?? '',
    customerBrand,
  );

  useEffect(() => {
    setSection('Loyalty');
    return () => setSection('OrderSearch');
  }, [setSection]);

  useEffect(() => {
    if (state.customerDetails) {
      setCustomerDetails(state.customerDetails.user);
      setProfileDetails(state.customerDetails.profile);
    }
  }, [state.customerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  if (section !== 'Loyalty') {
    return <Loader loadingMessage={t('messages.loading')} />;
  }

  const tabConfig = [
    {
      id: TabIds.CUSTOMER_PROFILE,
      name: TabNames.CUSTOMER_PROFILE,
      content: <CustomerDetails creditCard={state.creditCard} />,
      requestNames: [
        RetryRequestNames.CustomerDetails,
        RetryRequestNames.CardDetails,
        RetryRequestNames.WalletDetails,
        RetryRequestNames.WalletTransactions,
        RetryRequestNames.MemberOffers,
        RetryRequestNames.PunchCards,
        RetryRequestNames.Receipts,
      ],
    },
    ...(customerBrand !== 'salling'
      ? [
          {
            id: TabIds.WALLET,
            name: TabNames.WALLET,
            content: (
              <>
                <WalletDetailsHeader wallet={state.walletDetails} />
                <WalletTransaction
                  transactions={state.walletTransactions?.data || []}
                />
              </>
            ),
            requestNames: [
              RetryRequestNames.WalletDetails,
              RetryRequestNames.WalletTransactions,
            ],
            noDataTooltip: t('customTabPanel.noWallet'),
            disabled: !state.walletDetails,
          },
        ]
      : []),
    {
      id: TabIds.MEMBER_OFFERS,
      name: TabNames.MEMBER_OFFERS,
      content: <Offers initialOffers={state.offers?.memberOffers} />,
      requestNames: [RetryRequestNames.MemberOffers],
      noDataTooltip: t('customTabPanel.noOffers'),
      disabled:
        !Array.isArray(state.offers?.memberOffers) ||
        state.offers?.memberOffers.length === 0,
    },
    {
      id: TabIds.PUNCH_CARDS,
      name: TabNames.PUNCH_CARDS,
      content: (
        <PunchCardList
          initialPunchCards={state.punchCards?.memberPunchCards || []}
        />
      ),
      requestNames: [RetryRequestNames.PunchCards],
      noDataTooltip: t('customTabPanel.noPunchCards'),
      disabled:
        !Array.isArray(state.punchCards?.memberPunchCards) ||
        state.punchCards?.memberPunchCards.length === 0,
    },
    {
      id: TabIds.RECEIPTS,
      name: TabNames.RECEIPTS,
      content: <Receipt receipts={state.receipts || []} />,
      requestNames: [RetryRequestNames.Receipts],
      noDataTooltip: t('customTabPanel.noReceipts'),
      disabled: !Array.isArray(state.receipts) || state.receipts.length === 0,
    },
  ];

  const tabs: ICustomTab[] = tabConfig.map(
    ({ id, name, content, requestNames, noDataTooltip, disabled }) =>
      createTab({
        id,
        name,
        content,
        requestNames,
        state,
        dispatch,
        customerUuid: customerDetails?.uid ?? '',
        noDataTooltip,
        disabled,
      }),
  );

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <CustomTabPanel tabs={tabs} />
    </Box>
  );
};

export default Loyalty;
