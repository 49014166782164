import { msalInstance } from '../../../index';

export const getAdToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    );
  }

  const idTokenClaims = account.idTokenClaims;
  if (idTokenClaims && idTokenClaims.exp) {
    const forceRefresh = idTokenClaims.exp * 1000 < new Date().valueOf();
    if (forceRefresh) {
      console.info('Getting token silently with force refresh');
      const refreshedResponse = await msalInstance.acquireTokenSilent({
        scopes: ['User.Read'],
        account: account,
        forceRefresh: true,
      });
      return refreshedResponse.idToken;
    }
  }

  const response = await msalInstance.acquireTokenSilent({
    scopes: ['User.Read'],
    account: account,
  });

  return response.idToken;
};
