import './order-search.css';
import React from 'react';
import OrderTableWrapper from './table-columns/OrderTableWrapper';
import { Button } from 'antd';
import { DropboxOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Loader from '../mui-helpers/Loader';

const OrderSearch = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        className="icon-button"
        onClick={() => {
          navigate('stocks');
        }}
        icon={<DropboxOutlined />}
      >
        Lagerstatus
      </Button>
      <Loader area="order-list" />
      <Loader area="full-order-details" />
      <OrderTableWrapper />
    </>
  );
};

export default OrderSearch;
