import React, { useState } from 'react';
import { priceFormatter } from '../../../../utils/priceFormatter';
import Button from '@mui/material/Button';
import { emptyValueReplacement } from '../../../../utils/emptyValueReplacement';
import {
  ScrollableCardContent,
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '../../../mui-helpers/customComponentStyles';
import { IProduct } from '../../../../types/results/IOrderDetailsResult';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface ProductHistoryProps {
  history: IProduct['history'];
  productId: string;
}

const ProductHistory: React.FC<ProductHistoryProps> = ({
  history,
  productId,
}) => {
  const [showAllHistory, setShowAllHistory] = useState(false);
  const toggleShowAllHistory = () => {
    setShowAllHistory(!showAllHistory);
  };

  return (
    <StyledBorderCard variant="outlined">
      <ScrollableCardContent sx={{ flex: 1 }}>
        <StyledTitle>Produkt Historik</StyledTitle>
        {history
          .slice(0, showAllHistory ? history.length : 2)
          .map((historyItem, index) => (
            <React.Fragment key={`${index}-${productId}`}>
              <StyledDescription>
                <Typography variant="body2" mb={1}>
                  <strong>Status:</strong> {historyItem.status}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Fakturadato:</strong>{' '}
                  {emptyValueReplacement(historyItem.invoiceDate)}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Konsignationsnummer:</strong>{' '}
                  {emptyValueReplacement(historyItem.consignmentNumber)}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Forventet leveringsdato:</strong>{' '}
                  {emptyValueReplacement(historyItem.expectedDeliveryDate)}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Antal:</strong> {historyItem.quantity}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Total rabat:</strong>{' '}
                  {historyItem.totalDiscount != null
                    ? priceFormatter(historyItem.totalDiscount)
                    : 'N/A'}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Total pris:</strong>{' '}
                  {historyItem.totalPrice != null
                    ? priceFormatter(historyItem.totalPrice)
                    : 'N/A'}
                </Typography>
              </StyledDescription>
              <Divider sx={{ margin: '1.2rem 0' }} />
            </React.Fragment>
          ))}
        {history.length > 2 && (
          <Button onClick={toggleShowAllHistory} size="small" color="primary">
            {showAllHistory ? 'Se mindre' : 'Se mere'}
          </Button>
        )}
      </ScrollableCardContent>
    </StyledBorderCard>
  );
};

export default ProductHistory;
