import { Dispatch } from 'react';
import { LoyaltyAction } from '../../../components/loyalty/reducers/loyaltyReducer';
import {
  BrandKey,
  IProfile,
  IUser,
} from '../../../types/results/ICustomerDetailsResult';
import { fetchAllCustomerData } from '../../CustomerService';

interface FetchCustomerDataParams {
  customerDetails: IUser;
  profileDetails: IProfile;
  customerBrand: BrandKey;
  dispatch: Dispatch<LoyaltyAction>;
  showSnackbar: (message: string) => void;
  t: (key: string) => string;
}

export const fetchAndDispatchCustomerData = async ({
  customerDetails,
  profileDetails,
  customerBrand,
  dispatch,
  showSnackbar,
  t,
}: FetchCustomerDataParams) => {
  if (!customerDetails?.uid && !profileDetails?.trackingId && !customerBrand) {
    showSnackbar(t('messages.searchCustomerFirst'));
    return;
  }

  try {
    const {
      cardDetails,
      walletDetails,
      walletTransactions,
      memberOffers,
      punchCards,
      receipts,
      failedRequests,
      retryFunctions,
    } = await fetchAllCustomerData(
      customerDetails.email,
      customerDetails.uid,
      profileDetails.trackingId,
      customerBrand,
    );

    dispatch({ type: 'SET_CREDIT_CARD', payload: cardDetails?.data || null });
    dispatch({
      type: 'SET_WALLET_DETAILS',
      payload: walletDetails?.data || null,
    });
    dispatch({
      type: 'SET_WALLET_TRANSACTIONS',
      payload: walletTransactions?.data || null,
    });
    dispatch({ type: 'SET_OFFERS', payload: memberOffers?.data || null });
    dispatch({ type: 'SET_PUNCH_CARDS', payload: punchCards?.data || null });
    dispatch({ type: 'SET_RECEIPTS', payload: receipts?.data || null });
    dispatch({ type: 'SET_FAILED_REQUESTS', payload: failedRequests });
    dispatch({ type: 'SET_RETRY_FUNCTIONS', payload: retryFunctions });
  } catch (error: unknown) {
    console.error('Failed to fetch customer data:', error);
    showSnackbar(t('errors.customerContextSearch'));
  }
};
