import bilkaLogo from '../assets/images/logos/bilka_logo.svg';
import carlsJrLogo from '../assets/images/logos/carls_jr_logo.svg';
import fotexLogo from '../assets/images/logos/fotex_logo.svg';
import nettoLogo from '../assets/images/logos/netto_logo.svg';
import sallingLogo from '../assets/images/logos/salling_logo.svg';
import {
  BrandKey,
  BrandName,
  MembershipNrKeys,
  SignupDateKeys,
} from '../types/results/ICustomerDetailsResult';

enum brandsEnum {
  Bilka = 'bilka',
  BR = 'br',
  Foetex = 'foetex',
}

export const brandLogoMap: Record<BrandKey, string> = {
  bilka: bilkaLogo,
  carlsjr: carlsJrLogo,
  foetex: fotexLogo,
  nettode: nettoLogo,
  nettodk: nettoLogo,
  nettopl: nettoLogo,
  salling: sallingLogo,
  '': '',
};

export const getBrandsEnumKeys = () => {
  return brandsEnum;
};

export const getBrandLogo = (brand: BrandKey): string => {
  const logo = brandLogoMap[brand];
  if (!logo) {
    console.error(`${brand} is not a valid brand`);
    return '';
  }
  return logo;
};

export interface IBrandProgram {
  brandLogo: string;
  brandName: BrandName;
  membershipNr: MembershipNrKeys;
  signupDate: SignupDateKeys;
  brandKey?: BrandKey;
}

interface IBrandToProgramMapping {
  [key: string]: IBrandProgram;
}

export const brandToProgramMapping: IBrandToProgramMapping = {
  bilka: {
    brandLogo: bilkaLogo,
    brandName: 'Bilka',
    membershipNr: 'cpblkMembershipNr',
    signupDate: 'cpblkSignupDate',
  },
  carlsjr: {
    brandLogo: carlsJrLogo,
    brandName: "Carl's Jr.",
    membershipNr: 'cpcjrMembershipNr',
    signupDate: 'cpcjrSignupDate',
  },
  foetex: {
    brandLogo: fotexLogo,
    brandName: 'Føtex',
    membershipNr: 'cpfotMembershipNr',
    signupDate: 'cpfotSignupDate',
  },
  nettode: {
    brandLogo: nettoLogo,
    brandName: 'Netto DE',
    membershipNr: 'cpndeMembershipNr',
    signupDate: 'cpndeSignupDate',
  },
  nettodk: {
    brandLogo: nettoLogo,
    brandName: 'Netto DK',
    membershipNr: 'cpndkMembershipNr',
    signupDate: 'cpndkSignupDate',
  },
  nettopl: {
    brandLogo: nettoLogo,
    brandName: 'Netto PL',
    membershipNr: 'cpnplMembershipNr',
    signupDate: 'cpnplSignupDate',
  },
  salling: {
    brandLogo: sallingLogo,
    brandName: 'Salling',
    membershipNr: 'cpsalMembershipNr',
    signupDate: 'cpsalSignupDate',
  },
};
