import { IStore } from '../types/results/IStoreResult';
import { IShopRequestParams, IStockRequestParams } from '../types/payloads';
import { trackPromise } from 'react-promise-tracker';
import { IStock } from '../types/results/IStockResult';
import notification from 'antd/es/notification';
import axios, { AxiosRequestConfig } from 'axios';
import { IStockGet } from '../types/request/IStockGet';
import { appConfig } from '../appConfig';

interface IStockService {
  getStoresByBrand(params: IShopRequestParams): Promise<IStore[]>;

  getStocks(params: IStockRequestParams): Promise<IStock>;
}

class StockService implements IStockService {
  public getStoresByBrand = async (
    params: IShopRequestParams,
  ): Promise<IStore[]> => {
    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `${appConfig.sgApiStoreUrl}?brand=${params.brand}&per_page=${params.storesPerPage}`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          notification.error({
            message: `Ukendt undtagelse ${error}`,
            duration: 4,
          });
          throw error;
        }),
    );
  };

  public getStocks = async (params: IStockRequestParams): Promise<IStock> => {
    const config: AxiosRequestConfig<IStockGet> = {
      method: 'POST',
      url: `${appConfig.sgApiBaseUrlV1}stock-levels/articles/${params.articleId}/sites`,
      data: {
        sites: params.siteId,
      },
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          const response = error?.response;
          console.error(error);
          notification.error({
            message: `Error: ${response?.data?.errorMessage ? response?.data?.errorMessage : response?.data?.error}`,
            duration: 4,
          });
          throw error;
        }),
    );
  };
}

export default StockService;
