import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import ListItemWithIcon from '../../mui-helpers/ListItemWithIcon';
import { formatDate } from '../../../utils/date-utils/formatDate';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import {
  IMemberPunchCard,
  IPunchCardProperties,
  TacticType,
} from '../../../types/results/IPunchCards';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaymentIcon from '@mui/icons-material/Payment';
import NumbersIcon from '@mui/icons-material/Numbers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';

interface PunchCardInfoListProps {
  memberPunchCard: IMemberPunchCard;
}

const PunchCardInfoList: React.FC<PunchCardInfoListProps> = ({
  memberPunchCard,
}) => {
  const { t } = useTranslation();
  const renderListItemWithIcon = (
    icon: ReactElement,
    primary: string | number,
    secondary: string | number,
  ) => (
    <Grid item xs={12} md={4}>
      <ListItemWithIcon icon={icon} primary={primary} secondary={secondary} />
    </Grid>
  );

  const tacticTypeText: Record<TacticType, string> = {
    COMEBACK_VOUCHER: t('punchCard.comebackVoucherTooltip'),
    SPECIFIC_PRODUCTS: t('punchCard.specificProductsTooltip'),
    PRODUCT_CATEGORY: t('punchCard.productCategoryTooltip'),
    PURCHASE_TOTAL: t('punchCard.purchaseTotalTooltip'),
  };

  const renderTacticType = (memberPunchCard: IMemberPunchCard) => {
    const tacticType = memberPunchCard.punchCard?.tactic;
    let translation = t('UNKNOWN');
    if (tacticType) {
      translation = tacticTypeText[tacticType];
    }
    return (
      <Tooltip title={translation} arrow placement="right">
        {renderListItemWithIcon(
          <CheckCircleOutlineIcon />,
          t('punchCard.tactic'),
          emptyValueReplacement(tacticType),
        )}
      </Tooltip>
    );
  };

  const renderProperties = (properties: IPunchCardProperties | undefined) => {
    if (!properties) {
      return renderListItemWithIcon(
        <ErrorOutlineIcon />,
        t('punchCard.noPunchCardProperties'),
        'N/A',
      );
    }
    const { triggerSpendAmount, triggerArticleIds, triggerCategoryIds } =
      properties;
    let icon, text, value;

    if (triggerSpendAmount !== undefined) {
      icon = <PaymentIcon />;
      text = t('punchCard.triggerSpendAmount');
      value = triggerSpendAmount;
    } else if (triggerArticleIds && triggerArticleIds.length > 0) {
      icon = <NumbersIcon />;
      text = t('punchCard.triggerArticleIds');
      value = triggerArticleIds.join(', ');
    } else if (triggerCategoryIds && triggerCategoryIds.length > 0) {
      icon = <NumbersIcon />;
      text = t('punchCard.triggerCategoryIds');
      value = triggerCategoryIds.join(', ');
    } else {
      icon = <ErrorOutlineIcon />;
      text = t('punchCard.noPunchCardProperties');
      value = 'N/A';
    }

    return renderListItemWithIcon(icon, text, value);
  };

  return (
    <Grid container spacing={1}>
      {renderListItemWithIcon(
        <CalendarTodayIcon />,
        t('punchCard.validFrom'),
        memberPunchCard.punchCard?.validFrom
          ? formatDate(memberPunchCard.punchCard.validFrom)
          : 'N/A',
      )}
      {renderListItemWithIcon(
        <CalendarTodayIcon />,
        t('punchCard.validUntil'),
        memberPunchCard.punchCard?.validUntil
          ? formatDate(memberPunchCard.punchCard.validUntil)
          : 'N/A',
      )}
      {memberPunchCard.punchCard &&
        renderProperties(memberPunchCard.punchCard.properties)}
      {renderListItemWithIcon(
        <CalendarTodayIcon />,
        t('punchCard.punchCardCreatedAt'),
        memberPunchCard.punchCard?.createdAt
          ? formatDate(memberPunchCard.punchCard.createdAt)
          : 'N/A',
      )}
      {renderListItemWithIcon(
        <CalendarTodayIcon />,
        t('punchCard.punchCardModifiedAt'),
        memberPunchCard.punchCard?.modifiedAt
          ? formatDate(memberPunchCard.punchCard.modifiedAt)
          : 'N/A',
      )}
      {renderListItemWithIcon(
        <CalendarTodayIcon />,
        t('punchCard.punchCardIssuedAt'),
        memberPunchCard?.createdAt
          ? formatDate(memberPunchCard.createdAt)
          : 'N/A',
      )}
      {renderListItemWithIcon(
        <CalendarTodayIcon />,
        t('punchCard.lastCustomerInteraction'),
        memberPunchCard?.modifiedAt
          ? formatDate(memberPunchCard.modifiedAt)
          : 'N/A',
      )}
      {renderTacticType(memberPunchCard)}
    </Grid>
  );
};

export default PunchCardInfoList;
