import axios, { AxiosRequestConfig } from 'axios';
import { appConfig } from '../appConfig';
import { trackPromise } from 'react-promise-tracker';
import { IGetMemberOffers } from '../types/request/IGetOffers';
import { OffersData } from '../types/results/IOffersResults';
import { getCommonHeaders } from './request-helpers/loyalty/getLoyaltyCommonHeaders';
import { performADRequest } from './ApiHelper';
import { ICreditCardDetail } from '../components/loyalty/cards/ICreditCardDetail';
import { fetchReceipts } from './ReceiptService';
import { retryRequest } from './request-helpers/retryRequest';
import { fetchPunchCards } from './TradeDriverService';
import { fetchWalletDetails, fetchWalletTransactions } from './WalletService';
import { RetryRequestNames } from '../types/RetryRequestNames';
import { CustomError } from './CustomError';
import { ICustomerDetailsByBrand } from '../types/results/ICustomerDetailsResult';

export const fetchCustomerDetails = async (customerEmail: string) => {
  const options: AxiosRequestConfig = {
    method: `GET`,
    url: `${appConfig.sgApiBaseUrlV1}customer-service/customers/lookup?email=${customerEmail}`,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': `application/json`,
    },
    timeout: 5000,
  };
  return trackPromise(
    axios(options).then((response) => {
      return response.data;
    }),
  );
};

export const fetchCustomerDetailsWithBrand = async (
  customerEmail: string,
  brand: string,
): Promise<ICustomerDetailsByBrand> => {
  const encodedEmail = encodeURIComponent(customerEmail);
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile?email=${encodedEmail}`;
  const headers = await getCommonHeaders();
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers,
    timeout: 5000,
  };

  return trackPromise(
    axios(url, options).then((response) => {
      return response.data;
    }),
  );
};

export const fetchMemberOffers = async ({
  trackingId,
  brand,
  isActive,
  isRedeemable,
  validAt = new Date(),
  validFrom,
  validUntil,
  type,
  offerTacticType,
  offerTactic,
}: IGetMemberOffers): Promise<OffersData> => {
  let queryParams = new URLSearchParams();
  if (isActive !== undefined) queryParams.append('isActive', String(isActive));
  if (validAt) queryParams.append('validAt', validAt.toISOString());
  if (isRedeemable !== undefined)
    queryParams.append('isRedeemable', String(isRedeemable));
  if (validFrom) queryParams.append('validFrom', validFrom);
  if (validUntil) queryParams.append('validUntil', validUntil);
  if (type) queryParams.append('type', type);
  if (offerTacticType) queryParams.append('offerTacticType', offerTacticType);
  if (offerTactic)
    offerTactic.forEach((t) => queryParams.append('offerTactic', t));

  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/offers?${queryParams}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

export const fetchCardDetails = async (
  cdcId: string,
): Promise<ICreditCardDetail[]> => {
  const url = `${appConfig.sgApiBaseUrlV1}customers/${cdcId}/cards`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

const createRetryFunction = (
  fetchFunction: () => Promise<any>,
  retryRequestName: RetryRequestNames,
  area: string,
) => {
  return () =>
    retryRequest(() => trackPromise(fetchFunction(), area), retryRequestName);
};

/**
 * Fetches all customer data including card details, wallet details, wallet transactions, member offers, punch cards, and receipts.
 * If any request fails, it will be retried based on the retry logic defined in the retry functions.
 *
 * @param {string} customerEmail - The email address associated with the customer.
 * @param {string} cdcId - The CDC ID associated with the customer.
 * @param {string} trackingId - The tracking ID associated with the customer.
 * @param {string} brand - The brand associated with the customer.
 * @returns An object containing all the fetched customer data and retry functions for failed requests.
 */
export const fetchAllCustomerData = async (
  customerEmail: string,
  cdcId: string,
  trackingId: string,
  brand: string,
) => {
  try {
    const customerDetailsFn = createRetryFunction(
      () => fetchCustomerDetailsWithBrand(customerEmail, brand),
      RetryRequestNames.CustomerDetails,
      'customer-details-area',
    );
    const cardDetailsFn = createRetryFunction(
      () => fetchCardDetails(cdcId),
      RetryRequestNames.CardDetails,
      'card-details-area',
    );
    const walletDetailsFn = createRetryFunction(
      () => fetchWalletDetails(trackingId, brand),
      RetryRequestNames.WalletDetails,
      'wallet-details-area',
    );
    const walletTransactionsFn = createRetryFunction(
      () => fetchWalletTransactions(trackingId, brand),
      RetryRequestNames.WalletTransactions,
      'wallet-transactions-area',
    );
    const memberOffersFn = createRetryFunction(
      () => fetchMemberOffers({ trackingId, brand }),
      RetryRequestNames.MemberOffers,
      'updated-offers-area',
    );
    const punchCardsFn = createRetryFunction(
      () => fetchPunchCards(trackingId, brand, new Date()),
      RetryRequestNames.PunchCards,
      'receipt-list-area',
    );
    const receiptsFn = createRetryFunction(
      () => fetchReceipts(cdcId, brand, 100),
      RetryRequestNames.Receipts,
      'receipt-list-area',
    );

    const results = await Promise.allSettled([
      cardDetailsFn(),
      walletDetailsFn(),
      walletTransactionsFn(),
      memberOffersFn(),
      punchCardsFn(),
      receiptsFn(),
    ]);

    const failedRequests = results
      .filter(
        (result) =>
          result.status === 'fulfilled' &&
          result.value.failed &&
          result.value.attempts > 1,
      )
      .map((result) =>
        result.status === 'fulfilled' ? result.value.requestName : null,
      )
      .filter(Boolean);

    return {
      cardDetails: results[0].status === 'fulfilled' ? results[0].value : null,
      walletDetails:
        results[1].status === 'fulfilled' ? results[1].value : null,
      walletTransactions:
        results[2].status === 'fulfilled' ? results[2].value : null,
      memberOffers: results[3].status === 'fulfilled' ? results[3].value : null,
      punchCards: results[4].status === 'fulfilled' ? results[4].value : null,
      receipts: results[5].status === 'fulfilled' ? results[5].value : null,
      failedRequests,
      retryFunctions: {
        [RetryRequestNames.CustomerDetails]: customerDetailsFn,
        [RetryRequestNames.CardDetails]: cardDetailsFn,
        [RetryRequestNames.WalletDetails]: walletDetailsFn,
        [RetryRequestNames.WalletTransactions]: walletTransactionsFn,
        [RetryRequestNames.MemberOffers]: memberOffersFn,
        [RetryRequestNames.PunchCards]: punchCardsFn,
        [RetryRequestNames.Receipts]: receiptsFn,
      },
    };
  } catch (error) {
    throw new Error(`Failed to fetch all customer data: ${error}`);
  }
};

export const deleteLoyaltyCustomer = async (brand: string, gigyaId: string) => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/unsubscribe/${gigyaId}`;
  try {
    return await performADRequest('POST', url);
  } catch (error) {
    throw new Error(`Failed to delete loyalty: ${error}`);
  }
};

export const fetchLoyaltyProfileById = async (
  memberId: string,
): Promise<ICustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/loyalty/member/${memberId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by member ID:`);
  }
};
