import { ReceiptType } from '../types/results/IReceipt';

// Helper function to get localized receipt type
export const getLocalizedReceiptType = (
  receiptType: ReceiptType,
  t: (key: string) => string,
): string => {
  const receiptTypeToLocalized: Record<ReceiptType, string> = {
    LIGHT: t('receipt.light'),
    FULL: t('receipt.full'),
    MERGED: t('receipt.merged'),
  };
  return receiptTypeToLocalized[receiptType];
};
