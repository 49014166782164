import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import CardContent from '@mui/material/CardContent';
import { formatDate } from '../../../utils/date-utils/formatDate';
import { priceFormatter } from '../../../utils/priceFormatter';
import { IOrderDetailsResult } from '../../../types/results/IOrderDetailsResult';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import { StyledColoredCard } from '../../mui-helpers/customComponentStyles';

interface OrderDetailsHeaderProps {
  order: IOrderDetailsResult;
}

const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({ order }) => {
  const fields = [
    {
      label: 'Kundenavn',
      value: emptyValueReplacement(order.billingAddress.name),
    },
    {
      label: 'Bestillingsdato',
      value: order.orderDate ? formatDate(order.orderDate) : 'N/A',
    },
    {
      label: 'Fragt',
      value: order.deliveryPrice ? priceFormatter(order.deliveryPrice) : 'N/A',
    },
    {
      label: 'Samlet Rabat',
      value: order.totalDiscount ? priceFormatter(order.totalDiscount) : 'N/A',
    },
    {
      label: 'Salgskontor',
      value: order.salesOfficeDescription
        ? `${order.salesOfficeDescription}(${order.salesOfficeId})`
        : 'N/A',
    },
    { label: 'Shipping Address', value: order.shippingAddress?.name || 'N/A' },
  ];
  return (
    <Box>
      <StyledColoredCard>
        <CardContent>
          <Grid container spacing={1} alignItems="center" alignContent="center">
            {fields.map((field, index) => (
              <Grid item xs={6} sm={4} md={2} key={`${index}-${field.label}`}>
                <Typography
                  component="div"
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {field.label}
                </Typography>
                <Typography display="block" gutterBottom>
                  {field.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </StyledColoredCard>
    </Box>
  );
};

export default OrderDetailsHeader;
