import React, { useState } from 'react';
import './customer-remarks.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import styled from '@mui/system/styled';
import IconButton from '@mui/material/IconButton';
import EnterOutlined from '@ant-design/icons/EnterOutlined';
import { convertToSapDateFormat } from '../../../utils/date-utils/convertToSapDateFormat';
import InputBase from '@mui/material/InputBase';
import { useMsal } from '@azure/msal-react';
import { OrderService } from '../../../service/OrderService';
import { IOrderDetailsResult } from '../../../types/results/IOrderDetailsResult';
import RemarkContent from './RemarkContent';

// made it a sticky item so it is always shown
export const InputTextPaper = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '2px 4px',
  marginBottom: theme.spacing(2),
}));

interface CustomerRemarksLayoutNewProps {
  callBack: any;
  orderDetails: IOrderDetailsResult;
  brand: string | undefined;
}

const CustomerRemarks: React.FC<CustomerRemarksLayoutNewProps> = ({
  callBack,
  orderDetails,
  brand,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { instance } = useMsal();
  const orderService: OrderService = new OrderService();

  const addCustomerRemark = async () => {
    if (inputValue) {
      const account = instance.getActiveAccount();
      const firstName = account?.name ? account.name.split(' ')[0] : '';
      const email = account?.username ? account.username : '';

      const remarkText = `${convertToSapDateFormat(new Date())} ${email} ${firstName} ${inputValue}`;

      await orderService.postCustomerRemark({
        brand: brand as string,
        orderId: orderDetails.orderNumber,
        //erpOrderNumber in SAP has an additional "00" at the start
        erpOrderNumber: '00' + orderDetails.erpOrderNumber,
        customerRemarks: [remarkText],
      });

      callBack(remarkText);
      setInputValue('');
    }
  };
  return (
    <Container maxWidth="md">
      <InputTextPaper>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Skriv din note her"
          inputProps={{ 'aria-label': 'Skriv din note her' }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              addCustomerRemark();
            }
          }}
        />
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          aria-label="send"
          onClick={addCustomerRemark}
        >
          <EnterOutlined />
        </IconButton>
      </InputTextPaper>

      <Box mt={4} mb={4}>
        <RemarkContent remarks={orderDetails.customerRemarks} />
      </Box>
    </Container>
  );
};

export default CustomerRemarks;
