import React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { IAddress } from '../../../../types/results/IOrderDetailsResult';
import ScrollableAccordion from '../../../mui-helpers/ScrollableAccordion';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShippingAndBillingItem from './ShippingAndBillingItem';

interface ShippingAndBillingDetail {
  icon: React.ElementType;
  primary: string;
  key: keyof IAddress;
}

const shippingAndBillingDetails: ShippingAndBillingDetail[] = [
  { icon: PersonIcon, primary: 'Navn', key: 'name' },
  { icon: EmailIcon, primary: 'Email', key: 'email' },
  { icon: PhoneIphoneIcon, primary: 'Mobil', key: 'mobile' },
  { icon: LocationCityIcon, primary: 'Adresse', key: 'street' },
];

const renderShippingAndBillingDetails = (
  details: ShippingAndBillingDetail[],
  address: IAddress,
) => (
  <List sx={{ padding: '0', marginY: '0' }}>
    {details.map(({ icon, primary, key }, index) => (
      <ShippingAndBillingItem
        key={key}
        icon={icon}
        primary={primary}
        address={address}
        itemKey={key}
        isLastItem={index === details.length - 1}
      />
    ))}
  </List>
);

const ShippingAndBilling: React.FC<{
  billingAddress: IAddress;
  shippingAddress: IAddress;
}> = ({ billingAddress, shippingAddress }) => (
  <ScrollableAccordion
    title="Forsendelse / Faktureringsdetaljer"
    defaultExpanded
  >
    <Typography component="div" gutterBottom>
      Faktureringsdetaljer
    </Typography>
    {renderShippingAndBillingDetails(shippingAndBillingDetails, billingAddress)}
    <Typography component="div" gutterBottom>
      Forsendelsesdetaljer
    </Typography>
    {renderShippingAndBillingDetails(
      shippingAndBillingDetails,
      shippingAddress,
    )}
  </ScrollableAccordion>
);

export default ShippingAndBilling;
