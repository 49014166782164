import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { IProduct } from '../../../../types/results/IOrderDetailsResult';
import ProductInformation from './ProductInformation';
import ProductHistory from './ProductHistory';
import ProductTracking from './ProductTracking';

interface ProductDetailsCardProps {
  product: IProduct;
}

const ProductDetailsCard: React.FC<ProductDetailsCardProps> = ({ product }) => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <ProductInformation product={product} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <ProductHistory
            history={product.history}
            productId={product.productId}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <ProductTracking tracking={product.tracking} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductDetailsCard;
