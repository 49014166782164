import React, { useState, useEffect, useMemo, useCallback } from 'react';
import CardMedia from '@mui/material/CardMedia';
import { appConfig } from '../../appConfig';

interface CustomImageProps {
  baseImgUrl?: string;
  imageId?: string | null;
  title?: string;
  height?: number;
  fallbackImgUrl?: string;
  localFallbackImgUrl?: string;
  styles?: React.CSSProperties;
}

/**
 * Generates the default image URL based on the provided imageId, baseImgUrl, and fallbackImgUrl.
 * - If imageId is null or undefined, returns the fallbackImgUrl.
 * - Replaces .tiff extension with .png as it doesn't always load.
 * - Ensures the final imageId has a valid image extension (jpg, jpeg, png, bmp, webp).
 *
 * @param {string | null | undefined} imageId - The ID of the image.
 * @param {string} baseImgUrl - The base URL for the image.
 * @param {string} fallbackImgUrl - The fallback URL if imageId is not provided.
 * @returns {string} - The complete URL for the image.
 */
const getDefaultImgUrl = (
  imageId: string | null | undefined,
  baseImgUrl: string,
  fallbackImgUrl: string,
): string => {
  if (!imageId) return fallbackImgUrl;
  let finalImageId = imageId.replace(
    /(\.[a-z]{1,4})$|(?<!\.[a-z]{1,4})$/i,
    '.png',
  );
  return `${baseImgUrl}${finalImageId}`;
};

const CustomImage: React.FC<CustomImageProps> = ({
  height = 140,
  baseImgUrl = `${appConfig.sgImgStoreUrl}c_fit,q_auto,h_${height}/`,
  imageId,
  title,
  fallbackImgUrl = `${appConfig.sgImgStoreUrl}c_fit,q_auto,h_${height}/digital/general_editorial_content/general/no-image-found`,
  localFallbackImgUrl = 'no-image.jpg',
  styles,
}) => {
  const defaultImgUrl = useMemo(
    () => getDefaultImgUrl(imageId, baseImgUrl, fallbackImgUrl),
    [baseImgUrl, imageId, fallbackImgUrl],
  );

  const [imageUrl, setImageUrl] = useState<string>(defaultImgUrl);
  const [fallbackState, setFallbackState] = useState({
    hasTriedFallback: false,
    hasTriedLocalFallback: false,
  });

  useEffect(() => {
    setImageUrl(defaultImgUrl);
    setFallbackState({ hasTriedFallback: false, hasTriedLocalFallback: false });
  }, [defaultImgUrl]);

  const handleImageError = useCallback(() => {
    if (!fallbackState.hasTriedFallback) {
      setImageUrl(fallbackImgUrl);
      setFallbackState((prevState) => ({
        ...prevState,
        hasTriedFallback: true,
      }));
    } else if (!fallbackState.hasTriedLocalFallback) {
      setImageUrl(localFallbackImgUrl);
      setFallbackState((prevState) => ({
        ...prevState,
        hasTriedLocalFallback: true,
      }));
    }
  }, [fallbackState, fallbackImgUrl, localFallbackImgUrl]);

  const defaultStyles = {
    objectFit: 'contain',
    padding: 1,
  };

  return (
    <CardMedia
      component="img"
      height={height}
      image={imageUrl}
      alt={title || 'Product Image'}
      loading="lazy"
      onError={handleImageError}
      sx={{
        ...defaultStyles,
        ...styles,
      }}
    />
  );
};

export default CustomImage;
