import React from 'react';
import Divider from '@mui/material/Divider';
import { addressFormatter } from '../../../../utils/addressFormatter';
import { IAddress } from '../../../../types/results/IOrderDetailsResult';
import ListItemWithIcon from '../../../mui-helpers/ListItemWithIcon';

interface DetailItemProps {
  icon: React.ElementType;
  primary: string;
  address: IAddress;
  itemKey: keyof IAddress;
  isLastItem: boolean;
}

const ShippingAndBillingItem: React.FC<DetailItemProps> = ({
  icon: Icon,
  primary,
  address,
  itemKey,
  isLastItem,
}) => {
  const formatSecondaryText = (
    key: keyof IAddress,
    address: IAddress,
  ): string => {
    switch (key) {
      case 'street':
        return addressFormatter(address);
      default:
        return address[key] ? address[key].toString() : '';
    }
  };

  return (
    <>
      <ListItemWithIcon
        icon={<Icon />}
        primary={primary}
        secondary={formatSecondaryText(itemKey, address)}
      />
      {!isLastItem && <Divider />}
    </>
  );
};

export default ShippingAndBillingItem;
