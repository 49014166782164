import { ColumnGroupType } from 'antd/lib/table';
import { ColumnType } from 'antd/es/table';
import { StockLevels } from '../../../types/results/IStockResult';

export const stockColumns = [
  {
    title: 'Butik No.',
    dataIndex: 'siteId',
    key: 'siteId',
    width: '10%',
    sorter: (a, b) => parseInt(a.siteId) - parseInt(b.siteId),
  },
  {
    title: 'Nuværende Lager',
    dataIndex: 'currentStock',
    key: 'currentStock',
    width: '10%',
    sorter: (a, b) => a.currentStock - b.currentStock,
  },
] as (ColumnGroupType<StockLevels> | ColumnType<StockLevels>)[];
