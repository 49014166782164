import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMsal } from '@azure/msal-react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StyledButton } from '../components/user-app-bar/shared/styledNavBar';

const UserContextMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { instance } = useMsal();
  const { t } = useTranslation();
  const account = instance.getActiveAccount();
  const userName = account?.name ?? 'Unknown User';
  const firstName = userName.split(' ')[0];

  const navigate = useNavigate();

  const handleMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      authority: 'https://login.microsoftonline.com/common',
    });
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <>
      <StyledButton onClick={handleMenuToggle}>
        <Box display="flex" alignItems="center">
          <Avatar>{userName.charAt(0)}</Avatar>
          <Typography style={{ marginLeft: '0.5rem' }}>{firstName}</Typography>
          <ArrowDropDownIcon />
        </Box>
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuToggle}
        MenuListProps={{
          style: { width: anchorEl ? anchorEl.clientWidth : 'auto' },
        }}
      >
        <MenuItem
          onClick={handleProfileClick}
          style={{ color: 'black', marginRight: '0.5rem' }}
        >
          <AccountCircle />
          {t('contextMenu.profile')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon style={{ color: 'black', marginRight: '0.5rem' }} />
          {t('contextMenu.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserContextMenu;
