import { appConfig } from '../appConfig';
import {
  IWalletResult,
  IWalletTransactionResult,
} from '../types/results/IWalletResult';
import { performADRequest } from './ApiHelper';
import { CustomError } from './CustomError';

export const fetchWalletTransactions = async (
  trackingId: string,
  brand: string,
): Promise<IWalletTransactionResult> => {
  if ('salling' === brand) {
    throw new Error('API call is not allowed for brand "salling".');
  }
  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/wallets/${trackingId}/transactions`;
  try {
    return await performADRequest('GET', url);
  } catch (error: any) {
    throw CustomError.fromError(error, 'Failed to fetch transactions');
  }
};

export const fetchWalletDetails = async (
  trackingId: string,
  brand: string,
): Promise<IWalletResult> => {
  if ('salling' === brand) {
    throw new Error('API call is not allowed for brand "salling".');
  }
  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/wallets/${trackingId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error: any) {
    throw CustomError.fromError(error, 'Failed to fetch wallet details');
  }
};
