import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

interface LanguageMenuItemProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleLanguageChange: (locale: string) => void;
  flagMap: { [key: string]: string };
  t: (key: string) => string;
}

const LanguageMenuItem: React.FC<LanguageMenuItemProps> = ({
  anchorEl,
  handleClose,
  handleLanguageChange,
  flagMap,
  t,
}) => {
  const languages = ['en', 'da', 'de', 'pl'];
  return (
    <Menu
      id="language-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {languages.map((lang) => (
        <MenuItem key={lang} onClick={() => handleLanguageChange(lang)}>
          <Box display="flex" justifyContent="space-between" width="100%">
            {t(`languages.${lang}`)}
            <img
              src={flagMap[lang]}
              alt={`Flag of ${t(`languages.${lang}`)}`}
              style={{ width: 24, height: 24, marginLeft: '0.4rem' }}
            />
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LanguageMenuItem;
