import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import LanguageMenuItem from './LanguageMenuItem';
import enFlag from '../../assets/icons/flags/en.svg';
import daFlag from '../../assets/icons/flags/dk.svg';
import deFlag from '../../assets/icons/flags/de.svg';
import plFlag from '../../assets/icons/flags/pl.svg';
import { StyledButton } from './shared/styledNavBar';

const LanguageSwitch = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>('en');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const languageOpen = Boolean(anchorEl);

  useEffect(() => {
    const browserLanguage = window.navigator.language;
    if (browserLanguage) {
      setCurrentLanguage(browserLanguage.substring(0, 2));
    }
  }, []);

  const flagMap: { [key: string]: string } = {
    en: enFlag,
    da: daFlag,
    de: deFlag,
    pl: plFlag,
  };

  const handleLanguageChange = (locale: string) => {
    i18n.changeLanguage(locale);
    setCurrentLanguage(locale);
    setAnchorEl(null);
  };

  const handleOpenLanguageMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center">
      <StyledButton
        aria-controls={languageOpen ? 'language-menu' : undefined}
        aria-expanded={languageOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenLanguageMenu}
        size="large"
      >
        <img
          src={flagMap[currentLanguage]}
          alt={`${currentLanguage} flag`}
          style={{ width: 24, height: 24 }}
        />
      </StyledButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseLanguageMenu}
      >
        <LanguageMenuItem
          anchorEl={anchorEl}
          handleClose={handleCloseLanguageMenu}
          handleLanguageChange={handleLanguageChange}
          flagMap={flagMap}
          t={t}
        />
      </Menu>
    </Box>
  );
};

export default LanguageSwitch;
