import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import PunchCardDetails from './PunchCardDetails';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { IMemberPunchCard } from '../../../types/results/IPunchCards';
import {
  HoverListItem,
  StyledBorderCard,
  StyledTitle,
} from '../../mui-helpers/customComponentStyles';
import { useSnackbar } from '../../../contexts/SnackbarContextType';
import { fetchPunchCards } from '../../../service/TradeDriverService';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import { trackPromise } from 'react-promise-tracker';
import Loader from '../../mui-helpers/Loader';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import { containsSearchTerm } from '../../../utils/containsSearchTerm';
import CustomFilterOptions from '../../mui-helpers/CustomFilterOptions';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/date-utils/formatDate';
import DetailItem from '../../mui-helpers/DetailItem';
import RefreshButton from '../../mui-helpers/RefreshButton';

interface PunchCardListProps {
  initialPunchCards: IMemberPunchCard[] | null;
}

interface Filters {
  activeAt: Dayjs | null;
  isExpired: boolean | null;
  isCommenced: boolean | null;
  isConcluded: boolean | null;
  isRewardClaimed: boolean | null;
}

const PunchCardList: React.FC<PunchCardListProps> = ({ initialPunchCards }) => {
  const [punchCards, setPunchCards] = useState<IMemberPunchCard[] | null>(
    initialPunchCards,
  );
  const [selectedPunchCard, setSelectedPunchCard] =
    useState<IMemberPunchCard | null>(
      initialPunchCards ? initialPunchCards[0] : null,
    );
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<Filters>({
    activeAt: dayjs(),
    isExpired: null,
    isCommenced: null,
    isConcluded: null,
    isRewardClaimed: null,
  });
  const { showSnackbar } = useSnackbar();
  const { customerBrand, profileDetails } = useCustomerContext();
  const { t } = useTranslation();

  useEffect(() => {
    setPunchCards(initialPunchCards);
    setSelectedPunchCard(initialPunchCards?.[0] ?? null);
  }, [initialPunchCards]);

  const handleSelectPunchCard = useCallback((punchCard: IMemberPunchCard) => {
    setSelectedPunchCard(punchCard);
  }, []);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    [],
  );

  const filteredPunchCards = useMemo(() => {
    return punchCards?.filter((punchCard) =>
      containsSearchTerm(punchCard.punchCard, searchTerm),
    );
  }, [punchCards, searchTerm]);

  const handleApplyFilters = useCallback(async () => {
    let { activeAt, isExpired, isCommenced, isConcluded, isRewardClaimed } =
      filters;

    // Check if all optional filters are cleared
    const areOptionalFiltersCleared =
      !isExpired &&
      !isCommenced &&
      !isConcluded &&
      !isRewardClaimed &&
      !activeAt;

    if (areOptionalFiltersCleared) {
      // If filters are cleared, reset to initial punch cards from Loyalty
      setPunchCards(initialPunchCards);
      return;
    }

    try {
      if (profileDetails?.trackingId && customerBrand) {
        const punchCardResponse = await trackPromise(
          fetchPunchCards(
            profileDetails.trackingId,
            customerBrand,
            activeAt?.toDate(),
            isRewardClaimed,
            isExpired,
            isCommenced,
            isConcluded,
          ),
          'punch-card-area',
        );
        setPunchCards(punchCardResponse.memberPunchCards);
      }
    } catch (err) {
      console.error('Error fetching punch cards based on filters:', err);
      showSnackbar(t('punchCard.noPunchCardsFound'));
    }
  }, [filters, profileDetails, customerBrand, initialPunchCards]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleClearFilters = () => {
    setFilters({
      activeAt: null,
      isExpired: false,
      isCommenced: false,
      isConcluded: false,
      isRewardClaimed: false,
    });
  };

  const handleRefresh = async () => {
    const refreshedPunchCards = await fetchPunchCards(
      profileDetails?.trackingId ?? '',
      customerBrand,
      filters.activeAt ? filters.activeAt.toDate() : null,
      filters.isRewardClaimed,
      filters.isExpired,
      filters.isCommenced,
      filters.isConcluded,
    );
    setPunchCards(refreshedPunchCards.memberPunchCards);
    setSelectedPunchCard(refreshedPunchCards.memberPunchCards[0]);
  };

  const renderRedemptionPeriod = (punchCardItem: IMemberPunchCard) => {
    return (
      punchCardItem.punchCard?.rewardValidFrom &&
      punchCardItem.punchCard?.rewardValidUntil && (
        <DetailItem
          label={t('punchCard.redemptionPeriod')}
          value={`${formatDate(punchCardItem.punchCard.rewardValidFrom)} - ${formatDate(punchCardItem.punchCard.rewardValidUntil)}`}
        />
      )
    );
  };

  const renderPunchCardList = () => {
    return (
      <List sx={{ marginTop: 0, paddingTop: 0 }}>
        {filteredPunchCards?.map((item, index) => (
          <HoverListItem
            key={`${item.punchCard?.id}-${index}`}
            onClick={() => handleSelectPunchCard(item)}
            selected={selectedPunchCard?.punchCard?.id === item.punchCard?.id}
          >
            <Box>
              <StyledTitle>
                {emptyValueReplacement(item.punchCard?.name)}
              </StyledTitle>
              {renderRedemptionPeriod(item)}
            </Box>
          </HoverListItem>
        ))}
      </List>
    );
  };

  const renderSearchAndList = () => {
    return (
      <Grid item xs={12} sm={4}>
        <TextField
          label={t('punchCard.search')}
          variant="outlined"
          sx={{ marginBottom: '1rem' }}
          size="small"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <StyledBorderCard
          sx={{
            overflowY: 'auto',
            minHeight: {
              xs: '50vh',
              sm: '70vh',
              md: '100vh',
            },
          }}
        >
          {renderPunchCardList()}
        </StyledBorderCard>
      </Grid>
    );
  };

  const renderPunchCardDetails = () => {
    return (
      <Grid item xs={12} sm={8}>
        {selectedPunchCard && (
          <PunchCardDetails memberPunchCard={selectedPunchCard} />
        )}
      </Grid>
    );
  };

  const checkboxOptions = [
    {
      checked: filters.isExpired,
      key: 'isExpired',
      label: t('punchCard.isExpired'),
    },
    {
      checked: filters.isCommenced,
      key: 'isCommenced',
      label: t('punchCard.isCommenced'),
    },
    {
      checked: filters.isConcluded,
      key: 'isConcluded',
      label: t('punchCard.isConcluded'),
    },
    {
      checked: filters.isRewardClaimed,
      key: 'isRewardClaimed',
      label: t('punchCard.rewardClaimed'),
    },
  ];

  return (
    <Box>
      <Loader area="punch-card-area" />
      <CustomFilterOptions
        dateType="activeAt"
        dateValue={filters.activeAt}
        checkboxes={checkboxOptions}
        handleApplyFilters={handleApplyFilters}
        setFilters={setFilters}
        onClearFilters={handleClearFilters}
      />
      <RefreshButton onClick={() => handleRefresh()} />
      {!punchCards || punchCards.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
            textAlign: 'center',
          }}
        >
          <NoContentMessage infoMessage={t('punchCard.noPunchCardsFound')} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {renderSearchAndList()}
          {renderPunchCardDetails()}
        </Grid>
      )}
    </Box>
  );
};

export default PunchCardList;
