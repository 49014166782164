import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IOffer } from '../../../types/results/IPunchCards';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import { StyledDescription } from '../../mui-helpers/customComponentStyles';
import { useTranslation } from 'react-i18next';

interface PunchCardOfferDetailsProps {
  offer?: IOffer;
}

const PunchCardOfferDetails: React.FC<PunchCardOfferDetailsProps> = ({
  offer,
}) => {
  const { t } = useTranslation();
  if (!offer) return null;

  return (
    <Box
      sx={{
        textAlign: 'center',
        color: 'text.secondary',
        marginBottom: '1rem',
      }}
    >
      <StyledDescription>
        {emptyValueReplacement(offer.title)}
        {emptyValueReplacement(offer.description)}
      </StyledDescription>
      {offer.link && (
        <Button variant="text">
          <a href={offer.link} target="_blank" rel="noopener noreferrer">
            {t('punchCard.seeMore')}
          </a>
        </Button>
      )}
    </Box>
  );
};

export default PunchCardOfferDetails;
