import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from '../../utils/copyToClipboard';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

interface FieldWithCopyProps {
  value: string;
  label?: string;
  displayValue?: boolean;
}

const FieldWithCopy: React.FC<FieldWithCopyProps> = ({
  value,
  label,
  displayValue = true,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Tooltip title={`${t('messages.copy')} ${label ?? ''}`}>
        <IconButton onClick={() => copyToClipboard(value)} size="small">
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {displayValue && (
        <Typography variant="body2">
          {label ? `${label}: ${value}` : value}
        </Typography>
      )}
    </Box>
  );
};

export default FieldWithCopy;
