import React from 'react';
import Box from '@mui/material/Box';
import RefreshButton from './RefreshButton';
import { RetryRequestNames } from '../../types/RetryRequestNames';
import { ICustomTab } from './ICustomTab';
import RetryButton from './RetryButton';
import { t } from 'i18next';
import { handleManualRetry } from '../../service/request-helpers/loyalty/handleManualRetry';
import {
  LoyaltyAction,
  LoyaltyState,
} from '../loyalty/reducers/loyaltyReducer';
import { TabIds } from '../../types/LoyaltyTabsConfig';

interface CreateTabParams {
  id: string;
  name: string;
  content: React.ReactNode;
  requestNames: RetryRequestNames[];
  state: LoyaltyState;
  dispatch: React.Dispatch<LoyaltyAction>;
  customerUuid: string;
  noDataTooltip?: string;
  disabled?: boolean;
}

/**
 * Creates a custom tab configuration for the loyalty part of 360 with optional
 * retry and refresh buttons.
 *
 * The tab's name includes a retry button if there are failed requests and the
 * tab is not the customer profile tab. The tab's content includes a refresh button
 * if a customer UUID is provided, which means that a search was performed. We
 * don't want to show the refresh button if no search was performed.
 *
 * @param {CreateTabParams} params - The parameters for creating the tab.
 * @param {string} params.id - The unique identifier for the tab.
 * @param {string} params.name - The display name of the tab.
 * @param {React.ReactNode} params.content - The content to be displayed within the tab.
 * @param {RetryRequestNames[]} params.requestNames - The names of the requests associated with the tab.
 * @param {LoyaltyState} params.state - The current state of the loyalty component.
 * @param {React.Dispatch<LoyaltyAction>} params.dispatch - The dispatch function for updating the state.
 * @param {string} params.customerUuid - The UUID of the customer.
 * @param {string} [params.noDataTooltip] - Optional tooltip to display when there is no data.
 * @param {boolean} [params.disabled=false] - Optional flag to disable the tab.
 * @returns {ICustomTab} The custom tab object.
 *
 * Note: The refresh button is not generated for the `Offers` and `PunchCardList`
 * tabs because they have their own refresh buttons that have to take into
 * consideration their filters to fill out the request parameters.
 */
const createTab = ({
  id,
  name,
  content,
  requestNames,
  state,
  dispatch,
  customerUuid,
  noDataTooltip,
  disabled = false,
}: CreateTabParams): ICustomTab => {
  const handleMultipleRetries = (requestNames: RetryRequestNames[]) => {
    requestNames.forEach((requestName) =>
      handleManualRetry(requestName, state, dispatch),
    );
  };

  const failedRequests = requestNames.filter((requestName) =>
    state.failedRequests.includes(requestName),
  );

  const retryButton =
    failedRequests.length > 0 && TabIds.CUSTOMER_PROFILE !== id ? (
      <RetryButton onClick={() => handleMultipleRetries(failedRequests)} />
    ) : null;

  const refreshButton =
    customerUuid && id !== TabIds.MEMBER_OFFERS && id !== TabIds.PUNCH_CARDS ? (
      <RefreshButton onClick={() => handleMultipleRetries(requestNames)} />
    ) : null;

  return {
    id,
    name: (
      <Box display="flex" alignItems="center">
        {t(name)}
        {retryButton}
      </Box>
    ),
    content: (
      <Box key={id} p={3}>
        {content}
        {refreshButton}
      </Box>
    ),
    disabled,
    retryButton,
    noDataTooltip,
  };
};

export default createTab;
