import { IStore } from '../../../types/results/IStoreResult';

const Options = (
  foetexStores: IStore[],
  bilkaStores: IStore[],
  brStores: IStore[],
  onlineWarehouses: IStore[],
) => {
  const sortCondition = (a: IStore, b: IStore) =>
    a.sapSiteId < b.sapSiteId ? -1 : 1;

  const toOption = (store: IStore) => ({
    label: `${store.sapSiteId} - ${store.name}`,
    value: store.sapSiteId,
  });

  const sortAndConvertStores = (stores: IStore[]) => {
    if (!Array.isArray(stores)) {
      return [];
    }

    const sortedStores = [...stores].sort(sortCondition);
    return sortedStores.map(toOption);
  };

  return [
    {
      label: 'føtex',
      value: 'foetex',
      children: sortAndConvertStores(foetexStores),
    },
    {
      label: 'Bilka',
      value: 'bilka',
      children: sortAndConvertStores(bilkaStores),
    },
    {
      label: 'BR',
      value: 'br',
      children: sortAndConvertStores(brStores),
    },
    {
      label: 'Online',
      value: 'online',
      children: sortAndConvertStores(onlineWarehouses),
    },
  ];
};
export default Options;
