import { Dispatch } from 'react';
import { RetryRequestNames } from '../../../types/RetryRequestNames';
import { LoyaltyAction } from '../../../components/loyalty/reducers/loyaltyReducer';

/**
 * Handles the manual retry of a specific request.
 *
 * @param {RetryRequestNames} requestName - The name of the request to retry.
 * @param {any} state - The current state containing retry functions and failed requests.
 * @param {Dispatch<LoyaltyAction>} dispatch - The dispatch function to update the state.
 *
 * This function attempts to retry a request by calling the corresponding retry function
 * from the state. If the retry is successful and returns data, it updates the state
 * with the new data and removes the request from the list of failed requests.
 *
 * If the retry function is not found in the state, the function returns early.
 * If the retry function fails or does not return data, the function logs an error to the console.
 */
export const handleManualRetry = async (
  requestName: RetryRequestNames,
  state: any,
  dispatch: Dispatch<LoyaltyAction>,
) => {
  const retryFunction = state.retryFunctions[requestName];
  if (!retryFunction) return;

  try {
    const result = await retryFunction();
    if (!result.data) return;

    const updateState: Record<RetryRequestNames, LoyaltyAction['type']> = {
      [RetryRequestNames.CustomerDetails]: 'SET_CUSTOMER_DETAILS',
      [RetryRequestNames.CardDetails]: 'SET_CREDIT_CARD',
      [RetryRequestNames.WalletDetails]: 'SET_WALLET_DETAILS',
      [RetryRequestNames.WalletTransactions]: 'SET_WALLET_TRANSACTIONS',
      [RetryRequestNames.MemberOffers]: 'SET_OFFERS',
      [RetryRequestNames.PunchCards]: 'SET_PUNCH_CARDS',
      [RetryRequestNames.Receipts]: 'SET_RECEIPTS',
    };

    dispatch({ type: updateState[requestName], payload: result.data });
    dispatch({
      type: 'SET_FAILED_REQUESTS',
      payload: state.failedRequests.filter(
        (req: RetryRequestNames) => req !== requestName,
      ),
    });
  } catch (error) {
    console.error(`Failed to retry ${requestName}:`, error);
  }
};
