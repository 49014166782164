import { performADRequest } from './ApiHelper';
import { IPunchCardResponse } from '../types/results/IPunchCards';
import { appConfig } from '../appConfig';
import { CustomError } from './CustomError';

const buildPunchCardQueryParams = (
  activeAt?: Date | null,
  isRewardClaimed?: boolean | null,
  isExpired?: boolean | null,
  isCommenced?: boolean | null,
  isConcluded?: boolean | null,
): URLSearchParams => {
  const queryParams = new URLSearchParams();

  if (activeAt !== null && activeAt !== undefined)
    queryParams.append('activeAt', activeAt.toISOString());
  if (isRewardClaimed !== null && isRewardClaimed !== undefined)
    queryParams.append('isRewardClaimed', String(isRewardClaimed));
  if (isExpired !== null && isExpired !== undefined)
    queryParams.append('isExpired', String(isExpired));
  if (isCommenced !== null && isCommenced !== undefined)
    queryParams.append('isCommenced', String(isCommenced));
  if (isConcluded !== null && isConcluded !== undefined)
    queryParams.append('isConcluded', String(isConcluded));

  return queryParams;
};

/**
 * Fetch punch cards for a specific member
 * In the initial fetch (done in CustomerService.tsx), activeAt is set to the current date, this can be overridden by passing a different date
 */
export const fetchPunchCards = async (
  trackingId: string,
  brand: string,
  activeAt?: Date | null,
  isRewardClaimed?: boolean | null,
  isExpired?: boolean | null,
  isCommenced?: boolean | null,
  isConcluded?: boolean | null,
): Promise<IPunchCardResponse> => {
  const queryParams = buildPunchCardQueryParams(
    activeAt,
    isRewardClaimed,
    isExpired,
    isCommenced,
    isConcluded,
  );

  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/punch-cards?${queryParams.toString()}`;

  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch active punch cards`);
  }
};
