export const TabIds = {
  CUSTOMER_PROFILE: 'customerProfileTab',
  WALLET: 'walletTab',
  MEMBER_OFFERS: 'memberOffersTab',
  PUNCH_CARDS: 'punchCardsTab',
  RECEIPTS: 'receiptsTab',
};

export const TabNames = {
  CUSTOMER_PROFILE: 'loyaltyTabs.customerProfile',
  WALLET: 'loyaltyTabs.wallet',
  MEMBER_OFFERS: 'loyaltyTabs.memberOffers',
  PUNCH_CARDS: 'loyaltyTabs.punchCards',
  RECEIPTS: 'loyaltyTabs.receipts',
};
