import React, { useCallback, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from 'react-router-dom';
import LanguageSwitch from './LanguageSwitch';
import UserContextMenu from '../../contexts/UserContextMenu';
import SearchBar from '../order-details-view/SearchBar';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { useSnackbar } from '../../contexts/SnackbarContextType';
import {
  BrandKey,
  ICustomerDetailsByBrand,
} from '../../types/results/ICustomerDetailsResult';
import { SearchType } from '../../utils/checkInputType';
import {
  fetchCustomerDetails,
  fetchCustomerDetailsWithBrand,
  fetchLoyaltyProfileById,
} from '../../service/CustomerService';
import { useSectionContext } from '../../contexts/SectionContext';
import { AxiosError } from 'axios';
import { brandLogoMap } from '../../utils/brands-utils';
import { orderFetcherUtil } from '../../utils/orderFetcherUtil';
import { useFirestoreTicketListener } from '../../hooks/useFirestoreTicketListener';
import {
  StyledAppBar,
  StyledLogoBox,
  StyledTypography,
} from './shared/styledNavBar';

interface UserAppBarProps {
  isAuthorized: boolean | null;
  isLoyaltyAuthorized: boolean | null;
}

const UserAppBar: React.FC<UserAppBarProps> = ({
  isAuthorized,
  isLoyaltyAuthorized,
}) => {
  const navigate = useNavigate();
  const {
    customerBrand,
    setCustomerDetails,
    setProfileDetails,
    setCustomerBrand,
    setCustomerOrders,
    setCustomerEmail,
    resetStates,
  } = useCustomerContext();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { section } = useSectionContext();

  const initialBrand = customerBrand || Object.keys(brandLogoMap)[0];

  const [manualInput, setManualInput] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [selectedBrand, setSelectedBrand] = useState<BrandKey>(
    initialBrand as BrandKey,
  );

  // Set the selected brand to the customer's brand if it exists. This is used when we perform the search from ProgramMemberships.tsx
  useEffect(() => {
    if (customerBrand) {
      setSelectedBrand(customerBrand);
    }
  }, [customerBrand]);

  const handle360LogoClick = () => {
    navigate('/');
  };

  const handleLoyLogoClick = () => {
    navigate('/loyalty');
  };

  const handleFetchCustomerDetails = useCallback(
    async (inputQuery: string, brand: BrandKey, searchType: SearchType) => {
      if (!inputQuery && !brand) {
        showSnackbar(t('errors.selectBrandOrEmail'));
        return;
      }

      try {
        if (section === 'OrderSearch') {
          await orderSearch(inputQuery, brand);
        } else if (section === 'Loyalty') {
          searchType === SearchType.EMAIL
            ? await getLoyaltyDetailsByEmail(inputQuery, brand)
            : await getLoyaltyDetailsById(inputQuery);
        }
      } catch (error) {
        console.error('CustomerFetchDetails', error);
        const message =
          section === 'OrderSearch'
            ? t('errors.customerDetailsFetch')
            : t('errors.customerNotInLoyaltyProgram', { brand });
        showSnackbar(message);
        setErrorMessage(handleFailure(error));
        resetStates();
      }
    },
    [section], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const orderSearch = async (inputQuery: string, brand: BrandKey) => {
    try {
      const customerOrders = await orderFetcherUtil(inputQuery, brand);
      if (!customerOrders) return handleFetchError();

      const customerDetails = await fetchCustomerDetails(
        customerOrders[0].email,
      );
      if (!customerDetails) return handleFetchError();

      setCustomerBrand(brand);
      setCustomerDetails(customerDetails);
      setCustomerOrders(customerOrders);
    } catch (error) {
      console.error('CustomerFetchDetails', error);
      showSnackbar(t('errors.customerDetailsFetch'));
    }
  };

  const getLoyaltyDetailsByEmail = async (
    inputQuery: string,
    brand: BrandKey,
  ) => {
    try {
      const loyaltyResponse = await fetchCustomerDetailsWithBrand(
        inputQuery,
        brand,
      );
      loyaltyResponse
        ? updateStates(loyaltyResponse)
        : handleNoLoyaltyResponse(brand);
    } catch (error) {
      console.error('CustomerFetchDetails', error);
      showSnackbar(t('errors.customerDetailsFetch'));
    }
  };

  const getLoyaltyDetailsById = async (inputQuery: string) => {
    const loyaltyResponse = await fetchLoyaltyProfileById(inputQuery);
    if (loyaltyResponse) {
      updateStates(loyaltyResponse);
    } else {
      showSnackbar(t('errors.failedToFetchById', { brand: customerBrand }));
      resetStates();
    }
  };

  const updateStates = (loyaltyResponse: ICustomerDetailsByBrand) => {
    setCustomerDetails(loyaltyResponse.user);
    setProfileDetails(loyaltyResponse.profile);
    setCustomerBrand(loyaltyResponse.brand as BrandKey);
    setSelectedBrand(loyaltyResponse.brand as BrandKey);
    setCustomerEmail(loyaltyResponse.user.email);
  };

  const handleFetchError = () => {
    showSnackbar(t('errors.customerDetailsFetch'));
    resetStates();
  };

  const handleNoLoyaltyResponse = (brand: BrandKey) => {
    showSnackbar(t('errors.customerNotInLoyaltyProgram', { brand }));
    resetStates();
  };

  const handleFailure = (error: any) => {
    console.error('Error fetching customer details:', error);
    return error instanceof AxiosError
      ? error.response?.data?.message || error.message
      : error.message;
  };

  const showFirestoreDialog = useFirestoreTicketListener(
    handleFetchCustomerDetails,
  );

  return (
    <StyledAppBar position="static">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            {isAuthorized && (
              <StyledLogoBox onClick={handle360LogoClick}>
                <StyledTypography>Zendesk36</StyledTypography>
                <PublicIcon sx={{ fontSize: '1.125rem' }} />
              </StyledLogoBox>
            )}
            {isLoyaltyAuthorized && (
              <StyledLogoBox onClick={handleLoyLogoClick} sx={{ ml: 2 }}>
                <StyledTypography>Loyalty</StyledTypography>
              </StyledLogoBox>
            )}
          </Box>
          <SearchBar
            manualInput={manualInput}
            setManualInput={setManualInput}
            handleFetchCustomerDetails={handleFetchCustomerDetails}
            errorMessage={errorMessage}
            selectedBrand={selectedBrand}
            setSelectedBrand={(brand: BrandKey) => setSelectedBrand(brand)}
          />
          <Box display="flex" alignItems="center" height="100%">
            <LanguageSwitch />
            <UserContextMenu />
          </Box>
        </Box>
      </Toolbar>
      {showFirestoreDialog()}
    </StyledAppBar>
  );
};

export default UserAppBar;
