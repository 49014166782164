import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IShipment } from '../../../types/results/InShiftShipmentsResult';
import { formatDate } from '../../../utils/date-utils/formatDate';
import { StyledTitle } from '../../mui-helpers/customComponentStyles';

interface ShipmentStepsProps {
  shipment: IShipment;
}

const ShipmentSteps: React.FC<ShipmentStepsProps> = ({ shipment }) => {
  const generateShipmentSteps = () => {
    return shipment.events.map((event) => ({
      title: event.translatedStatusName,
      description: formatDate(event.date),
    }));
  };
  const steps = generateShipmentSteps();

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" p={2}>
        <StyledTitle>{shipment.packages.length} pakke(r)</StyledTitle>
      </Box>
      <Stepper alternativeLabel activeStep={shipment.events.length}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ShipmentSteps;
