import { IOrderDetailsResult } from '../types/results/IOrderDetailsResult';
import {
  IGetOrderDetailsParams,
  IGetOrderListParams,
  IPostCustomerRemark,
} from '../types/payloads';
import { IOrderResult } from '../types/results/IOrderResult';
import { trackPromise } from 'react-promise-tracker';
import axios, { AxiosRequestConfig } from 'axios';
import notification from 'antd/es/notification';
import { ICustomerRemarkPost } from '../types/request/ICustomerRemarkPost';
import { appConfig } from '../appConfig';

interface IOrderService {
  getShortOrderDetails(params: IGetOrderDetailsParams): Promise<IOrderResult[]>;

  getFullOrderDetails(
    params: IGetOrderDetailsParams,
  ): Promise<IOrderDetailsResult>;

  getOrderList(params: IGetOrderListParams): Promise<IOrderResult[]>;

  postCustomerRemark(params: IPostCustomerRemark): Promise<void>;
}

export class OrderService implements IOrderService {
  public getShortOrderDetails = async (
    params: IGetOrderDetailsParams,
  ): Promise<IOrderResult[]> => {
    try {
      const orderDetails = await this.getFullOrderDetails(params);
      const orderObject: IOrderResult = {
        orderId: orderDetails.orderNumber,
        price: orderDetails.total - orderDetails.totalDiscount,
        email: orderDetails.billingAddress.email,
        phone: orderDetails.billingAddress.mobile,
        orderTime: new Date(orderDetails.orderDate),
      };
      return Promise.resolve([orderObject]);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public getFullOrderDetails = async (
    params: IGetOrderDetailsParams,
  ): Promise<IOrderDetailsResult> => {
    const brand = params.brand ? params.brand : 'bilka';
    const options: AxiosRequestConfig = {
      method: `GET`,
      url: `${appConfig.sgApiNextOrdersUrlV2}${brand}/orders/${params.orderId}`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(options).then((response) => {
        return response.data;
      }),
      'full-order-details',
    );
  };

  public getOrderList = async (
    params: IGetOrderListParams,
  ): Promise<IOrderResult[]> => {
    const url = `${appConfig.sgApiNextOrdersUrlV1}${params.brand}/orders?${params.identifier}=${params.input}`;
    const options: AxiosRequestConfig = {
      method: `GET`,
      url: url,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(options).then((response) => {
        return response.data;
      }),
      'order-list',
    );
  };

  public postCustomerRemark = async (
    params: IPostCustomerRemark,
  ): Promise<void> => {
    const options: AxiosRequestConfig<ICustomerRemarkPost> = {
      method: 'POST',
      url: `${appConfig.sgApiNextOrdersUrlV1}${params.brand}/orders/${params.orderId}/remarks/`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      data: {
        erpOrderNumber: params.erpOrderNumber,
        customerRemarks: params.customerRemarks,
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          notification.error({
            message: `Kunne ikke oprette kommentar på ordren.  ${error}`,
            duration: 4,
          });
          throw error;
        }),
    );
  };
}
