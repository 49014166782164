import isErrorInstance from '../error-handler/IsErrorInstance';
import notification from 'antd/es/notification';
import { OrderService } from '../service/OrderService';
import { IOrderResult } from '../types/results/IOrderResult';
import { checkInputType } from './checkInputType';

export const orderFetcherUtil = async (
  input: string | number,
  brand: string,
) => {
  if (input) {
    try {
      const inputType = checkInputType(input);
      return fillTableByIdentifier(input, inputType, brand);
    } catch (error: unknown) {
      if (error && isErrorInstance(error)) {
        notification.error({
          message: error.responseJSON
            ? error.responseJSON.message
            : 'Ukendt fejl',
          description: error.description,
          duration: 4,
        });
      }
      console.error(error);
    }
  }
};

const fillTableByIdentifier = async (
  input: string | number,
  identifier: string,
  brand: string,
) => {
  const orderService: OrderService = new OrderService();
  if (identifier === 'orderNo') {
    return orderService.getShortOrderDetails({ orderId: input, brand: brand });
  } else {
    const orders: IOrderResult[] = await orderService.getOrderList({
      identifier: identifier,
      brand: brand,
      input: input,
    });
    if (orders.length === 0) {
      notification.error({
        message: `Ingen ordre fundet i ${brand} til ${input}`,
        duration: 4,
      });
    }
    return orders;
  }
};
