import { IGetNShiftShipmentsParams } from '../types/payloads';
import { trackPromise } from 'react-promise-tracker';
import { InShiftShipmentsResult } from '../types/results/InShiftShipmentsResult';
import axios from 'axios';
import notification from 'antd/es/notification';
import { appConfig } from '../appConfig';

interface INShiftService {
  getNShiftShipments(
    params: IGetNShiftShipmentsParams,
  ): Promise<InShiftShipmentsResult>;
}

export class NShiftService implements INShiftService {
  public getNShiftShipments = async (
    params: IGetNShiftShipmentsParams,
  ): Promise<InShiftShipmentsResult> => {
    const options = await this.setOptions(params);
    return trackPromise(
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          notification.error({
            message: `Failed to get shipments: ${error}`,
            duration: 4,
          });
          throw error;
        }),
    );
  };

  //  if the dates are not provided, then a search for the last 31 days will be performed
  public setOptions = async (params: IGetNShiftShipmentsParams) => {
    let url = `${appConfig.sgApiNshiftShipments}${params.query}`;
    if (params.startDate && params.endDate) {
      url += `?startDate=${params.startDate}&endDate=${params.endDate}`;
    }
    return {
      method: `GET`,
      url: url,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
  };
}
