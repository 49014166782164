import {
  IReceiptOrderResult,
  IReceiptSummaryResult,
} from '../types/results/IReceipt';
import { performADRequest } from './ApiHelper';
import { appConfig } from '../appConfig';
import { CustomError } from './CustomError';

export const fetchReceipts = async (
  gigyaId: string,
  brand: string,
  pageSize: number = 100,
  offset: number = 0,
  fromDate?: Date,
  toDate?: Date,
): Promise<IReceiptSummaryResult[]> => {
  const receiptUrl = new URL(
    `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${gigyaId}/receipts`,
  );

  receiptUrl.searchParams.append('limit', pageSize.toString());
  receiptUrl.searchParams.append('offset', offset.toString());

  if (fromDate) {
    receiptUrl.searchParams.append('fromDate', fromDate.toISOString());
  }

  if (toDate) {
    receiptUrl.searchParams.append('toDate', toDate.toISOString());
  }

  try {
    return await performADRequest('GET', receiptUrl.toString());
  } catch (error) {
    throw new Error(`Failed to fetch Receipts: ${error}`);
  }
};

export const fetchReceiptById = async (
  gigyaId: string,
  brand: string,
  receiptId: string,
): Promise<IReceiptOrderResult> => {
  const receiptIdUrl = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${gigyaId}/receipts/${receiptId}`;

  try {
    return await performADRequest('GET', receiptIdUrl);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch Receipt by Id`);
  }
};
