import { appConfig } from '../appConfig';
import { trackPromise } from 'react-promise-tracker';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { getCommonHeaders } from './request-helpers/loyalty/getLoyaltyCommonHeaders';

export const performRequest = async <T, D>(
  method: Method,
  url: string,
  data?: D,
  timeout = 5000,
  headers: { [key: string]: string } = {
    Authorization: `Bearer ${appConfig.sgBearer}`,
    'Content-Type': 'application/json',
  },
): Promise<T> => {
  const requestOptions: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: headers,
    data: data,
    timeout: timeout,
  };

  const response = await trackPromise(axios(requestOptions));
  return response.data;
};

export const performADRequest = async <T, D>(
  method: Method | string,
  url: string,
  data?: D,
  timeout = 5000,
): Promise<T> => {
  const headers = await getCommonHeaders();
  const requestOptions: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: headers,
    data: data,
    timeout: timeout,
  };

  const response = await trackPromise(axios(requestOptions));
  return response.data;
};
