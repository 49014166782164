import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import da from './locales/da.json';
import pl from './locales/pl.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  da: {
    translation: da,
  },
  pl: {
    translation: pl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  supportedLngs: ['de', 'en', 'da', 'pl'],
  detection: {
    order: ['localStorage', 'navigator'],
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
