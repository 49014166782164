import { appConfig } from '../../../appConfig';
import { getAdToken } from './getAdToken';

export const getCommonHeaders = async () => {
  const token = await getAdToken();
  return {
    Authorization: `Bearer ${token}`,
    'SG-APIM-ROUTER': 'B',
    'SG-APIM-IDENTIFIER-ID': appConfig.sgApimIdentifierId,
  };
};
