import { Cascader, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import Search from 'antd/es/input/Search';
import { IShopRequestParams } from '../../../types/payloads';
import { IStore } from '../../../types/results/IStoreResult';
import React, { useEffect, useState } from 'react';
import { stockColumns } from '../../order-details-view/table-columns/stockColumns';
import { IStock, StockLevels } from '../../../types/results/IStockResult';
import './stocks-layout.css';
import Options from './Options';
import StockService from '../../../service/StockService';
import notification from 'antd/es/notification';
import { requestValidation } from '../../../utils/requestValidation';

const StocksLayout = () => {
  const [foetexStores, setFoetexStores] = useState<IStore[]>();
  const [bilkaStores, setBilkaStores] = useState<IStore[]>();
  const [brStores, setBrStores] = useState<IStore[]>();
  const [onlineWarehouses, setOnlineWarehouses] = useState<IStore[]>();
  const [siteIds, setSiteIds] = useState<number[]>();
  const [stocks, setStocks] = useState<StockLevels[]>();
  const storesPerPage = 200;

  useEffect(() => {
    const brands = ['foetex', 'bilka', 'br'];
    brands.forEach((brand) => {
      performShopSearch({ brand, storesPerPage });
    });
    setOnlineWarehouses([
      { brand: 'online', sapSiteId: '9002', name: 'Årslev' },
      { brand: 'online', sapSiteId: '9010', name: 'Skejby' },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stockService = new StockService();
  const { SHOW_CHILD } = Cascader;

  const getSideIdsOnChange = (stores: any[]) => {
    // Grabbing last index point, to get siteIds, as first index is Brand.
    const ids = stores.map((store) => store.at(1));
    setSiteIds(ids);
  };

  const performShopSearch = async (params: IShopRequestParams) => {
    const response = await stockService.getStoresByBrand({
      brand: params.brand,
      storesPerPage: params.storesPerPage,
    });
    if (params.brand === 'foetex') {
      setFoetexStores(response);
    } else if (params.brand === 'bilka') {
      setBilkaStores(response);
    } else if (params.brand === 'br') {
      setBrStores(response);
    }
  };

  const settingStocks = (stockResponse: IStock) => {
    const mappedBrands: Record<string, Map<string, string>> = {
      '16': bilkaMap,
      '20': brMap,
      '90': onlineWarehouseMap,
      default: foetexMap,
    };

    const stockLevels: StockLevels[] = stockResponse.stockLevels
      .filter((stockLevel) => stockLevel.currentStock !== 0)
      .map((stockLevel) => {
        const mappedBrand =
          mappedBrands[stockLevel.siteId.slice(0, 2)] || mappedBrands.default;
        stockLevel.siteId += ' - ' + mappedBrand?.get(stockLevel.siteId);
        return stockLevel;
      });

    setStocks(stockLevels);
  };

  const returnStoreMap = (stores: IStore[]) => {
    return new Map(
      stores?.map((object) => {
        return [object.sapSiteId, object.name];
      }),
    );
  };

  const foetexMap = returnStoreMap(foetexStores ? foetexStores : []);
  const bilkaMap = returnStoreMap(bilkaStores ? bilkaStores : []);
  const brMap = returnStoreMap(brStores ? brStores : []);
  const onlineWarehouseMap = returnStoreMap(onlineWarehouses || []);

  const onSearch = async (value: string) => {
    try {
      requestValidation(value, siteIds!);
      const response = await stockService.getStocks({
        articleId: value,
        siteId: siteIds!,
      });
      settingStocks(response);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        notification.warning({
          message:
            error && error.message
              ? error.message + ' for article id: ' + value
              : 'Aktier ikke fundet',
          duration: 4,
        });
      }
      setStocks([]);
    }
  };

  return (
    <div>
      <PageHeader title={'Stock levels'}></PageHeader>
      <Cascader
        multiple
        placeholder={'Klik for at vælge en butik'}
        allowClear
        onChange={(store) => getSideIdsOnChange(store)}
        options={Options(
          foetexStores!,
          bilkaStores!,
          brStores!,
          onlineWarehouses!,
        )}
        maxTagCount="responsive"
        showCheckedStrategy={SHOW_CHILD}
      />
      <h4>Artikelnr:</h4>
      <Search
        placeholder="Indsæt artikelnummer her"
        onSearch={(value) => onSearch(value)}
        type="number"
        enterButton
      ></Search>
      <Table
        id={'stocks-table'}
        columns={stockColumns}
        dataSource={stocks}
        rowKey={(stock) => stock.siteId}
        pagination={{ pageSize: 6, hideOnSinglePage: true }}
      />
    </div>
  );
};
export default StocksLayout;
