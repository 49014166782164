import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

interface AlertSnackbarProps {
  open: boolean;
  message: string;
  onClose?: () => void;
  position?: SnackbarOrigin;
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
  open,
  message,
  position = { vertical: 'top', horizontal: 'right' },
  onClose,
}) => {
  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // handler so no close event is fired for clicks outside or inside the SnackBar
    if (reason === 'clickaway') {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={position}
    >
      <Alert
        onClose={handleClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
