import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { appConfig } from '../appConfig';
import { BrandKey } from '../types/results/ICustomerDetailsResult';
import { useSectionContext } from '../contexts/SectionContext';
import { useSnackbar } from '../contexts/SnackbarContextType';
import { useTranslation } from 'react-i18next';

interface BrandSelectProps {
  selectedBrand: BrandKey;
  setSelectedBrand: (brand: BrandKey) => void;
}

export const BrandSelect: React.FC<BrandSelectProps> = ({
  selectedBrand,
  setSelectedBrand,
}) => {
  const { section } = useSectionContext();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const filteredBrands =
    section === 'OrderSearch'
      ? appConfig.orderSearchBrands
      : appConfig.loyaltyBrands;

  if (!filteredBrands.some((brand) => brand.value === selectedBrand)) {
    showSnackbar(
      t('messages.nonSupportedBrand', {
        section,
        brand: selectedBrand,
        defaultBrand: filteredBrands[0].label,
      }),
    );
    setSelectedBrand(filteredBrands[0].value);
  }

  return (
    <Select
      sx={{
        height: '2.5rem',
        marginLeft: '-0.95rem',
        '& .MuiOutlinedInput-notchedOutline': {
          borderLeft: 'none',
          borderBottom: 'none',
          borderTop: 'none',
        },
      }}
      value={selectedBrand}
      onChange={(e) => setSelectedBrand(e.target.value as BrandKey)}
    >
      {filteredBrands.map((brand) => (
        <MenuItem key={brand.value} value={brand.value}>
          {brand.label}
        </MenuItem>
      ))}
    </Select>
  );
};
