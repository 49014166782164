import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import search from '../../assets/images/search.svg';
import { TextConstants } from '../../utils/TextConstant';

interface InfoMessageProps {
  infoMessage?: string;
}

const NoContentMessage: React.FC<InfoMessageProps> = ({
  infoMessage = TextConstants.MESSAGES.noData,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="20%"
      my={2}
    >
      <InfoOutlined fontSize="large" color="primary" />
      <Typography
        variant="button"
        color="primary"
        sx={{ my: 2, textAlign: 'center' }}
      >
        {infoMessage}
      </Typography>
      <Box
        sx={{
          my: 1,
          p: 1,
          width: '20%',
          mx: 'auto',
        }}
      >
        <img
          src={search}
          style={{
            width: '100%',
            margin: 'auto',
            display: 'block',
            height: 'auto',
          }}
          alt="No results found"
        />
      </Box>
    </Box>
  );
};

export default NoContentMessage;
