import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import UserAppBar from './components/user-app-bar';
import StocksLayout from './components/order-search-view/stocks-view';
import OrderSearch from './components/order-search-view';
import OrderDetailsTab from './components/order-details-view/OrderDetailsTab';
import { CustomerProvider } from './contexts/CustomerContext';
import { SnackbarProvider } from './contexts/SnackbarContextType';
import NotFound from './components/./not-found/NotFound';
import Loyalty from './components/loyalty/Loyalty';
import { useAuthorizationHook } from './hooks/useAuthorizationHook';
import { SectionProvider } from './contexts/SectionContext';
import { CollapseProvider } from './contexts/CollapseContext';
import Loader from './components/mui-helpers/Loader';
import ProfilePage from './components/profile/ProfilePage';

const AppRouter = () => {
  const isAuthenticated = useIsAuthenticated();
  const { isAuthorized, isLoyaltyAuthorized } = useAuthorizationHook();

  if (
    (isAuthorized === null || isLoyaltyAuthorized === null) &&
    isAuthenticated
  ) {
    return <Loader loadingMessage="Indlæser" />;
  }

  if (!isAuthenticated) {
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div>Redirecting to login...</div>
      </MsalAuthenticationTemplate>
    );
  }

  return (
    <SnackbarProvider>
      <Router>
        <CustomerProvider>
          <SectionProvider>
            <UserAppBar
              isAuthorized={isAuthorized}
              isLoyaltyAuthorized={isLoyaltyAuthorized}
            />
            <Routes>
              {isAuthorized && (
                <>
                  <Route path="/" element={<OrderSearch />} />
                  <Route path="stocks" element={<StocksLayout />} />
                  <Route
                    path="/brands/:brand/orders/:orderId"
                    element={
                      <CollapseProvider>
                        <OrderDetailsTab />
                      </CollapseProvider>
                    }
                  />
                  <Route path="profile" element={<ProfilePage />} />
                </>
              )}
              {isLoyaltyAuthorized && !isAuthorized && (
                <Route path="/" element={<Navigate to="/loyalty" replace />} />
              )}
              {isLoyaltyAuthorized && (
                <Route path="/loyalty" element={<Loyalty />} />
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </SectionProvider>
        </CustomerProvider>
      </Router>
    </SnackbarProvider>
  );
};

export default AppRouter;
