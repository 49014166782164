import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { IShipment } from '../../../types/results/InShiftShipmentsResult';
import AddressDetails from './AddressDetails';
import ShipmentInfo from './ShipmentInfo';
import ShipmentEvents from './ShipmentEvents';

interface ShipmentDetailsProps {
  shipment: IShipment;
}

const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({ shipment }) => {
  return (
    <Container maxWidth="lg" sx={{ marginBottom: '20px', padding: '20px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <AddressDetails title="Modtager" address={shipment.receiver} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <AddressDetails title="Afsender" address={shipment.sender} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <ShipmentInfo shipment={shipment} />
        </Grid>
        <Grid item xs={12}>
          <ShipmentEvents events={shipment.events} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShipmentDetails;
