import { useEffect, useReducer, useCallback } from 'react';
import { fetchAllCustomerData } from '../service/CustomerService';
import {
  initialState,
  reducer,
} from '../components/loyalty/reducers/loyaltyReducer';
import { useSnackbar } from '../contexts/SnackbarContextType';
import { useTranslation } from 'react-i18next';

const useFetchCustomerData = (
  customerEmail: string,
  customerUuid: string,
  customerTrackingId: string,
  customerBrand: string,
) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllCustomerData = useCallback(async () => {
    if (customerUuid && customerTrackingId && customerBrand) {
      try {
        const {
          cardDetails,
          walletDetails,
          walletTransactions,
          memberOffers,
          punchCards,
          receipts,
          failedRequests,
          retryFunctions,
        } = await fetchAllCustomerData(
          customerEmail,
          customerUuid,
          customerTrackingId,
          customerBrand,
        );

        dispatch({
          type: 'SET_CREDIT_CARD',
          payload: cardDetails?.data || null,
        });
        dispatch({
          type: 'SET_WALLET_DETAILS',
          payload: walletDetails?.data || null,
        });
        dispatch({
          type: 'SET_WALLET_TRANSACTIONS',
          payload: walletTransactions?.data || null,
        });
        dispatch({ type: 'SET_OFFERS', payload: memberOffers?.data || null });
        dispatch({
          type: 'SET_PUNCH_CARDS',
          payload: punchCards?.data || null,
        });
        dispatch({ type: 'SET_RECEIPTS', payload: receipts?.data || null });
        dispatch({ type: 'SET_FAILED_REQUESTS', payload: failedRequests });
        dispatch({ type: 'SET_RETRY_FUNCTIONS', payload: retryFunctions });
      } catch (error: unknown) {
        console.error('Failed to fetch customer data:', error);
        showSnackbar(t('errors.customerContextSearch'));
      }
    }
  }, [
    customerUuid,
    customerTrackingId,
    customerBrand,
    customerEmail,
    showSnackbar,
    t,
  ]);

  useEffect(() => {
    getAllCustomerData();
  }, [getAllCustomerData]);

  return { state, dispatch, getAllCustomerData };
};

export default useFetchCustomerData;
