import React, { useState, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import ListItemWithIcon from '../mui-helpers/ListItemWithIcon';
import { AccountCircle, Security } from '@mui/icons-material';
import {
  StyledDescription,
  StyledTitle,
} from '../mui-helpers/customComponentStyles';
import { useTranslation } from 'react-i18next';
import DetailItem from '../mui-helpers/DetailItem';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const ProfilePage: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<string>('details');
  const { t } = useTranslation();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  if (!account) {
    throw new Error('No active account found');
  }

  const agentInfo = useMemo(
    () => ({
      name: account.name || 'Agent Name',
      roles: account.idTokenClaims?.roles || [],
      email: account.username,
    }),
    [account],
  );

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  const detailsContent: { [key: string]: JSX.Element } = {
    details: (
      <>
        <StyledTitle>{t('agentProfile.details')}</StyledTitle>
        <DetailItem label={t('agentProfile.name')} value={agentInfo.name} />
        <DetailItem label={t('agentProfile.email')} value={agentInfo.email} />
      </>
    ),
    roles: (
      <>
        <StyledTitle>{t('agentProfile.roles')}</StyledTitle>
        {agentInfo.roles.length > 0 ? (
          agentInfo.roles.map((role, index) => (
            <StyledDescription key={index}>{role}</StyledDescription>
          ))
        ) : (
          <StyledDescription>{t('agentProfile.noRoles')}</StyledDescription>
        )}
      </>
    ),
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} sx={{ padding: 2, height: '100%' }}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={2}
            sx={{
              padding: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Avatar
                alt={agentInfo.name || 'User'}
                src={''}
                sx={{ width: 80, height: 80 }}
              />
              <StyledTitle gutterBottom>{agentInfo.name}</StyledTitle>
              <StyledDescription gutterBottom>
                {agentInfo.email}
              </StyledDescription>
            </Box>

            <List>
              <ListItemWithIcon
                icon={<AccountCircle />}
                secondary={t('agentProfile.details')}
                onClick={() => handleItemClick('details')}
              />
              <ListItemWithIcon
                icon={<Security />}
                secondary={t('agentProfile.roles')}
                onClick={() => handleItemClick('roles')}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
            {detailsContent[selectedItem]}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
