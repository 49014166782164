import React, { useMemo, useReducer } from 'react';
import {
  BrandKey,
  IAccountData,
  MembershipNrKeys,
  SignupDateKeys,
} from '../../../types/results/ICustomerDetailsResult';
import { useTranslation } from 'react-i18next';
import {
  StyledDescription,
  StyledHeader,
} from '../../mui-helpers/customComponentStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
  brandToProgramMapping,
  IBrandProgram,
} from '../../../utils/brands-utils';
import { appConfig } from '../../../appConfig';
import { fetchCustomerDetailsWithBrand } from '../../../service/CustomerService';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { useSnackbar } from '../../../contexts/SnackbarContextType';
import { initialState, reducer } from '../reducers/loyaltyReducer';
import { fetchAndDispatchCustomerData } from '../../../service/request-helpers/loyalty/customerDataUtils';
import ProgramCard from './ProgramCard';

interface CustomerProgramProps {
  accountData: IAccountData;
}

type CustomerProgramType = {
  [key in MembershipNrKeys | SignupDateKeys]: string | null;
};

/**
 * Function to get brand details for a specific brand
 * @param brandKey - The key for the brand in the mapping (e.g. 'bilka', 'carlsjr')
 * @param customerProgram - The customer program data containing membership numbers and signup dates
 * @returns An object with brandLogo, brandName, membershipNr, signupDate, and brandKey (or null if not found)
 */
const getBrandDetails = (
  brandKey: BrandKey,
  customerProgram: CustomerProgramType,
) => {
  const brandMapping = brandToProgramMapping[brandKey];
  if (!brandMapping) {
    return null;
  }
  const membershipNrKey = brandMapping.membershipNr;
  const signupDateKey = brandMapping.signupDate;
  const membershipNr = customerProgram[membershipNrKey] || null;
  const signupDate = customerProgram[signupDateKey] || null;
  return {
    brandLogo: brandMapping.brandLogo,
    brandName: brandMapping.brandName,
    membershipNr,
    signupDate,
    brandKey,
  };
};

const ProgramMemberships: React.FC<CustomerProgramProps> = ({
  accountData,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { customerProgram } = accountData;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const {
    customerDetails,
    profileDetails,
    customerBrand,
    customerEmail,
    setCustomerDetails,
    setProfileDetails,
    setCustomerBrand,
    setCustomerEmail,
    resetStates,
  } = useCustomerContext();

  /**
   * Reduces the list of brands to an array of brand details where the customer has a membership Id or signup date for the brand.
   */
  const customerBrandDetails = useMemo(() => {
    return appConfig.loyaltyBrands.reduce<IBrandProgram[]>((acc, brand) => {
      if (!customerProgram) {
        return acc;
      }
      const brandDetail = getBrandDetails(brand.value, customerProgram);
      if (brandDetail?.membershipNr || brandDetail?.signupDate) {
        acc.push(brandDetail as IBrandProgram);
      }
      return acc;
    }, []);
  }, [customerProgram]);

  const renderNoPrograms = () => {
    if (customerProgram === null || customerBrandDetails.length === 0) {
      return (
        <StyledDescription sx={{ padding: '1rem' }}>
          {t('customerProgram.noPrograms')}
        </StyledDescription>
      );
    }
    return null;
  };

  const fetchCustomerProfile = async (brandKey: BrandKey) => {
    if (!customerEmail || !brandKey || !profileDetails?.trackingId) {
      showSnackbar(t('messages.searchCustomerFirst'));
      return;
    }

    //saving the customer email, then resetting states in customer context so it can be fetched again.
    const tempCustomerEmail = customerEmail;
    resetStates();

    try {
      const loyaltyResponse = await fetchCustomerDetailsWithBrand(
        tempCustomerEmail,
        brandKey,
      );
      setProfileDetails(loyaltyResponse.profile);
      setCustomerDetails(loyaltyResponse.user);
      setCustomerBrand(brandKey as BrandKey);
      setCustomerEmail(loyaltyResponse.user.email);
      handleFetchCustomerData();
    } catch (error) {
      console.error('Failed to fetch customer data:', error);
      showSnackbar(t('errors.failedToFetchById'));
    }
  };

  const handleFetchCustomerData = async () => {
    if (customerDetails?.uid && profileDetails?.trackingId && customerBrand) {
      try {
        await fetchAndDispatchCustomerData({
          customerDetails,
          profileDetails,
          customerBrand,
          dispatch,
          showSnackbar,
          t,
        });
      } catch (error) {
        console.error(
          'An error occurred while fetching and dispatching customer data:',
          error,
        );
        showSnackbar(t('errors.customerDetailsFetch'));
      }
    } else {
      showSnackbar(t('errors.customerDetailsFetch'));
    }
  };

  if (!state) {
    return null;
  }

  const renderProgramMemberships = () => (
    <Grid container paddingX={4} gap={4}>
      {customerBrandDetails.map((detail, index) => (
        <ProgramCard
          detail={detail}
          index={index}
          fetchCustomerProfile={fetchCustomerProfile}
          key={`${detail.brandName}-${index}`}
        />
      ))}
    </Grid>
  );

  return (
    <Accordion sx={{ my: 2 }} defaultExpanded component={Paper} elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="program-memberships"
      >
        <StyledHeader gutterBottom>{t('customerProgram.title')}</StyledHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderProgramMemberships()}
          {renderNoPrograms()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProgramMemberships;
