import React from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '../user-app-bar/shared/styledNavBar';

interface RefreshButtonProps {
  onClick: () => void;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledButton
      sx={{ position: 'absolute', top: '1.5rem', right: '2.4rem' }}
      onClick={onClick}
      size="small"
      startIcon={<ReplayIcon />}
      color="primary"
    >
      {t('generic.refresh')}
    </StyledButton>
  );
};

export default RefreshButton;
