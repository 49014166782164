import React, { useEffect, useState } from 'react';
import { IReceiptSummaryResult } from '../../../types/results/IReceipt';
import Grid from '@mui/material/Grid';
import ReceiptList from './ReceiptList';
import ReceiptItem from './ReceiptItem';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

interface IReceiptProps {
  receipts: IReceiptSummaryResult[];
}

const Receipt: React.FC<IReceiptProps> = ({ receipts }) => {
  const [selectedReceiptId, setSelectedReceiptId] = useState<string | null>(
    null,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (receipts.length > 0) {
      setSelectedReceiptId(receipts[0].id);
    }
  }, [receipts]);

  return (
    <Box>
      {!receipts || receipts.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
            textAlign: 'center',
          }}
        >
          <NoContentMessage infoMessage={t('receipt.noReceipts')} />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <ReceiptList
              receipts={receipts}
              onSelectReceipt={setSelectedReceiptId}
            />
          </Grid>
          {/*Show ReceiptItem if a receipt is clicked in ReceiptList component */}
          <Grid item xs={12} md={8}>
            {selectedReceiptId && <ReceiptItem receiptId={selectedReceiptId} />}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Receipt;
