import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCustomerContext } from '../contexts/CustomerContext';
import { useTranslation } from 'react-i18next';
import { SearchType } from '../utils/checkInputType';
import { BrandKey } from '../types/results/ICustomerDetailsResult';

export const useFirestoreTicketListener = (
  handleFetchCustomerDetails: (
    inputQuery: string,
    brand: BrandKey,
    searchType: SearchType,
  ) => void,
) => {
  const { instance } = useMsal();
  const { setCustomerEmail, setCustomerBrand } = useCustomerContext();
  const [open, setOpen] = useState(false);
  const [ticketData, setTicketData] = useState<{
    input: string;
    brand: string;
    ticketId: string | number;
  } | null>(null);

  const account = instance.getActiveAccount();
  const userName = account?.username ?? 'Unknown User';

  const { t } = useTranslation();

  useEffect(() => {
    const docRef = doc(db, 'users', userName);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        const data = snapshot.data();
        const ticket = data?.tickets?.[data.tickets.length - 1];
        if (!ticket?.brandName) {
          return;
        }
        setCustomerEmail(ticket?.requesterEmail);
        setCustomerBrand(ticket?.brandName);
        const input =
          ticket?.orderNumber ||
          ticket?.requesterEmail ||
          ticket?.requesterPhoneNumber;
        const brand = ticket?.brandName;
        const ticketId = ticket?.ticketId;

        if (input && brand && ticketId) {
          setTicketData({ input, brand, ticketId });
          setOpen(true);
        }
      },
      (error) => {
        console.error('Error getting document From firestore: ', error);
      },
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
    // Reasonable to ignore because we don't want to include the setter of the context in the dependency array
  }, [userName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = () => {
    if (ticketData) {
      handleFetchCustomerDetails(
        ticketData.input,
        ticketData.brand as BrandKey,
        SearchType.EMAIL,
      );
    }
    setOpen(false);
  };

  return () => {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{`Billet ID: ${ticketData?.ticketId}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('ticketDialog.newTicketFound', { brand: ticketData?.brand })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  };
};
