import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IOrderTableStructure } from '../search-result-table/IOrderTableStructure';
import { priceFormatter } from '../../../utils/priceFormatter';
import { formatDate } from '../../../utils/date-utils/formatDate';
import React from 'react';
import OrderTable from '../search-result-table';
import { useNavigate } from 'react-router-dom';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { IOrderResult } from '../../../types/results/IOrderResult';

const OrderTableWrapper = () => {
  const navigate = useNavigate();
  const { customerBrand, customerOrders } = useCustomerContext();

  const orderColumns: ColumnsType<IOrderTableStructure | IOrderResult> = [
    {
      title: 'Ordre',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (orderId) => (
        <Button
          type="primary"
          onClick={() => {
            navigate(`brands/${customerBrand}/orders/${orderId}`);
          }}
        >
          {orderId}
        </Button>
      ),
    },
    {
      title: 'Pris',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (price: number) => priceFormatter(price),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ordre dato',
      dataIndex: 'orderTime',
      key: 'orderTime',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        new Date(a.orderTime).getTime() - new Date(b.orderTime).getTime(),
      render: (invoiceDate: string) => formatDate(invoiceDate),
    },
  ];

  if (!customerOrders) {
    return null;
  }

  return (
    <OrderTable
      id="order-list-table"
      size="small"
      columns={orderColumns}
      dataSource={customerOrders}
      paginationOptions={{
        defaultPageSize: 5,
        pageSizeOptions: ['3', '5', '10', '20', '30'],
        size: 'small',
      }}
    />
  );
};

export default OrderTableWrapper;
