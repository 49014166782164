import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { hashToColor } from '../../../utils/hashToColor';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';

interface RemarksDisplayProps {
  remarks: string[];
}

const RemarkContent: React.FC<RemarksDisplayProps> = ({ remarks }) => {
  const filteredRemarks = remarks.map((remark) =>
    emptyValueReplacement(remark),
  );

  return (
    <Box mt={4} mb={4}>
      {filteredRemarks.length > 0 && (
        <>
          {filteredRemarks.map((remark, index) => (
            <Box key={index} display="flex" alignItems="flex-start" mt={2}>
              <Avatar
                style={{
                  backgroundColor: hashToColor(
                    typeof remark === 'string' ? remark.substring(13) : '',
                  ),
                  marginRight: '2rem',
                }}
                src="/broken-image.jpg"
              />
              <Box>
                <div
                  className={`speech-bubble ${
                    index % 2 === 0 ? 'gray' : 'blue'
                  }`}
                >
                  <Typography
                    component="div"
                    gutterBottom
                    sx={{ overflowWrap: 'break-word' }}
                  >
                    {remark}
                  </Typography>
                </div>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default RemarkContent;
