import { Table } from 'antd';
import { IOrderTableStructure } from './IOrderTableStructure';
import {
  ColumnGroupType,
  ColumnType,
  TablePaginationConfig,
} from 'antd/es/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { IOrderResult } from '../../../types/results/IOrderResult';

interface IOrderTable {
  id: string;
  size: SizeType;
  columns: (
    | ColumnGroupType<IOrderTableStructure | IOrderResult>
    | ColumnType<IOrderTableStructure | IOrderResult>
  )[];
  dataSource: readonly IOrderResult[];
  paginationOptions?: TablePaginationConfig;
  pagination?: false | true;
  expandable?: ExpandableConfig<IOrderTableStructure | IOrderResult>;
}

const OrderTable = (params: IOrderTable) => {
  return (
    <Table
      id={params.id}
      size={params.size}
      columns={params.columns}
      dataSource={params.dataSource}
      pagination={params.paginationOptions}
      expandable={params.expandable}
    />
  );
};

export default OrderTable;
