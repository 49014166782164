import React, { useCallback, useMemo } from 'react';
import { formatDate } from '../../../utils/date-utils/formatDate';
import { currencyFormatter } from '../../../utils/priceFormatter';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import { IWalletTransaction } from '../../../types/results/IWalletResult';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Loader from '../../mui-helpers/Loader';

interface IWalletTransactionProps {
  transactions: IWalletTransaction[] | [];
}

const WalletTransaction: React.FC<IWalletTransactionProps> = ({
  transactions,
}) => {
  const { t } = useTranslation();
  const getBalanceColorStyle = useCallback(
    (oldBalance: number, newBalance: number): React.CSSProperties => ({
      color: oldBalance > newBalance ? 'red' : 'green',
    }),
    [],
  );

  const transactionColumns: GridColDef<IWalletTransaction>[] = useMemo(
    () => [
      {
        field: 'amount',
        headerName: t('walletTransaction.amount'),
        flex: 1,
        renderCell: (params) => {
          const balanceChange =
            params.row.oldBalance > params.row.newBalance ? '-' : '';

          return (
            <span
              style={getBalanceColorStyle(
                params.row.oldBalance,
                params.row.newBalance,
              )}
            >
              {balanceChange}
              {currencyFormatter(params.value, params.row.currency)}
            </span>
          );
        },
      },
      {
        field: 'newBalance',
        headerName: t('walletTransaction.newBalance'),
        flex: 1,
        renderCell: (params) =>
          currencyFormatter(params.value, params.row.currency),
      },
      {
        field: 'createdAt',
        headerName: t('walletTransaction.createdAt'),
        flex: 1,
        renderCell: (params) => formatDate(params.value),
      },
    ],
    [transactions, t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const rows = useMemo(
    () =>
      transactions?.map((transaction) => ({
        ...transaction,
        id: transaction.id,
      })) || [],
    [transactions],
  );

  if (!transactions || transactions.length === 0) {
    return <NoContentMessage infoMessage={t('walletTransaction.noData')} />;
  }

  return (
    <Box sx={{ px: 3 }}>
      <Loader area="wallet-transactions-area" />
      <Paper elevation={3} style={{ width: '100%' }}>
        <DataGrid
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bolder',
            },
          }}
          rows={rows}
          columns={transactionColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
        />
      </Paper>
    </Box>
  );
};

export default WalletTransaction;
