export enum SearchType {
  EMAIL = 'email',
  MEMBER_ID = 'memberId',
  ORDER_NO = 'orderNo',
}

export const checkInputType = (input: string | number): SearchType => {
  if (typeof input !== 'number') {
    input = input.replace(/\s/g, '');
  }

  if (!isNaN(input as number)) {
    return SearchType.ORDER_NO;
  }
  return SearchType.EMAIL;
};

/**
 * Checks the type of the input and returns 'numberInput' if it's a number, otherwise 'email'.
 * @param {string | number} input - The input to check.
 * @returns {string} - The type of the input ('numberInput' or 'email').
 */
export const checkInputTypeForLoyalty = (
  input: string | number,
): SearchType => {
  if (typeof input !== 'number') {
    input = input.replace(/\s/g, '');
  }
  if (!isNaN(input as number)) {
    return SearchType.MEMBER_ID;
  }
  return SearchType.EMAIL;
};

/**
 * Checks if the input is a valid email.
 * @param {string} email - The email to check.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * Sanitizes the input by removing unwanted characters and trimming it.
 * @param {string} input - The input to sanitize.
 * @returns {string} - The sanitized input.
 */
export const sanitizeInput = (input: string): string => {
  const sanitized = input
    .replace(/[&<>"'/]/g, '')
    .replace(/\u00A0/g, ' ')
    .replace(/\t/g, '');
  return sanitized.trim();
};
