import React, { createContext, useContext, useMemo, useState } from 'react';

interface SectionContextType {
  section: 'Loyalty' | 'OrderSearch';
  setSection: (section: 'Loyalty' | 'OrderSearch') => void;
}

const SectionContext = createContext<SectionContextType | undefined>(undefined);

export const useSectionContext = () => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error('useSectionContext must be used within a SectionProvider');
  }
  return context;
};

export const SectionProvider = (props: { children: React.ReactNode }) => {
  const [section, setSection] = useState<'Loyalty' | 'OrderSearch'>(
    'OrderSearch',
  );

  const value = useMemo(() => ({ section, setSection }), [section, setSection]);

  return (
    <SectionContext.Provider value={value}>
      {props.children}
    </SectionContext.Provider>
  );
};
