import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { MemberOffer } from '../../../types/results/IOffersResults';
import { formatDate } from '../../../utils/date-utils/formatDate';
import { emptyValueReplacement } from '../../../utils/emptyValueReplacement';
import DetailRow from './DetailRow';
import { StyledTitle } from '../../mui-helpers/customComponentStyles';
import offerPlaceholder from '../../../assets/images/placeholders/offer_placeholder.png';
import CustomImage from '../../mui-helpers/CustomImage';
import InfoIconButton from '../../mui-helpers/InfoIconButton';
import { useTranslation } from 'react-i18next';

const customImageStyles: React.CSSProperties = {
  width: '20rem',
  height: '40vh',
  objectFit: 'contain',
  objectPosition: 'center',
  marginTop: '1rem',
};

interface OfferDetailsProps {
  offer: MemberOffer;
}

const getDetails = (offer: MemberOffer, t: (key: string) => string) => {
  return [
    {
      label: t('generic.validFrom'),
      value: formatDate(offer.offer.validFrom),
    },
    {
      label: t('generic.validUntil'),
      value: offer.offer.validUntil
        ? formatDate(offer.offer.validUntil)
        : 'N/A',
    },
    { label: t('offerDetails.redeemCount'), value: offer.redeemCount },
    { label: t('offerDetails.redeemLimit'), value: offer.redeemLimit },
    {
      label: t('offerDetails.isActive'),
      value: offer.isActive ? t('generic.yes') : t('generic.no'),
    },
    {
      label: t('generic.createdAt'),
      value: offer.createdAt ? formatDate(offer.createdAt) : 'N/A',
    },
    {
      label: t('generic.modifiedAt'),
      value: offer.modifiedAt ? formatDate(offer.modifiedAt) : 'N/A',
    },
  ];
};

const OfferDetails: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();
  const details = getDetails(offer, t);

  return (
    <Card sx={{ m: 2, position: 'relative' }} elevation={4}>
      <InfoIconButton
        description={emptyValueReplacement(offer.offer.description) as string}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <CustomImage
          imageId={offer.offer.image}
          title={offer.offer.title}
          height={300}
          fallbackImgUrl={offerPlaceholder}
          styles={customImageStyles}
        />
        <CardContent>
          <StyledTitle>
            {emptyValueReplacement(offer.offer.title ?? offer.offer.id)}
          </StyledTitle>
          <Box marginTop="1vh">
            {details.map(({ label, value }, index) => (
              <DetailRow
                key={`${index}-${label}`}
                label={label}
                value={value}
              />
            ))}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default OfferDetails;
